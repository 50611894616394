

/**
 * Booking-engine component styles
 */

.search-btn {
  background: @white;
  border: 2px solid @yellow;
  bottom: 25px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  color: @yellow;
  font-size: 0;
  line-height: 46 * @rem;
  min-height: 50px;
  min-width: 50px;
  position: fixed;
  right: 45px;
  text-align: center;
  transition: all 150ms linear;
  z-index: 599;

  &:focus {
    z-index: 600;
  }

  @media @smartphone {
    bottom: 10px;
    right: 10px;
  }

  .icon-fonts {
    font-size: 25 * @rem;
    transition: all 300ms linear;
  }
}

.booking-engine,
#ui-datepicker-div {
  display: none;
}

.msg_error_input {
  background: white;
  color: red;
  position: relative;
  top: 30px;
  z-index: 1;
}
