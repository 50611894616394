@bg-color-eb: #f2d166;
@font-size-eb: 10px;
@height-eb: 4em;

.adunit[data-adunit='overTopHeader']{
  &.is-done {
    align-items: center;
    background-color: @bg-color-eb;
    display: flex;
    font-size: @font-size-eb;
    height: @height-eb;
    left: 0;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 20px;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
  }

  &.display-block {
    > *:not(.emergencyClose) {
      height: 100% !important;
      padding: 0 10px;
      width: 100% !important;
    }

    iframe {
      display: block;
      height: 100%;
      margin: auto;
      max-width: 100%;
      width: 100%;
    }

    .emergencyClose {
      background: transparent;
      border: 0;
      text-align: center;

      &:before {
        color: #252339;
        content: '\2716';
        display: table-cell;
        font-size: 1.5em;
        text-align: center;
        vertical-align: middle;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
