/**
 * Gallery component styles
 */
.gallery {
  font-size: 0;
  padding-top: 40px;
  text-align: center;

  @media @smartphone {
    margin: 0 -10px;
  }

  &__button {
    background: none;
    border: 0;
    color: @lightgrey;
    font-size: 14 * @rem;
    font-weight: 700;
    line-height: 1;
    margin: 15px auto;
    opacity: 0;
    position: relative;
    text-decoration: none;
    transition: opacity 0.3s;

    .has-next & {
      opacity: 1;
    }

    .icon-fonts {
      margin-bottom: 5px;
      transition: transform 500ms linear;

      .gallery__button:hover& {
        transform: rotate(-360deg);
      }
    }
  }

  &__image {
    display: block;
    font-family: 'object-fit: cover';
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__item {
    display: inline-block;
    height: 180px;
    margin: 10px;
    position: relative;
    width: 180px;

    @media @smartphone {
      margin: 5px;
      max-width: calc(50% - 20px);
    }

    &:before {
      color: @white;
      font-size: 35 * @rem;
      position: absolute;
      right: 0;
      text-align: right;
      top: 0;
      z-index: 10;
    }
  }
}

.section {
  &--join-our-community-grey-background {
    background: #f8f8f8;
    padding-top: 50px;
  }

  &--join-our-community {
    .section {
      &__baseline {
        font-size: 0;
        margin: 0 auto;
        width: 400px;

        .emoji {
          display: inline-block;
          height: auto;
          margin-left: 3px;
          vertical-align: middle;
          width: 16px;
        }

        @media @smartphone {
          max-width: 440px;
          width: 100%;
        }

        &__logo {
          background: @yellow;
          border-radius: 99px;
          display: inline-block;
          height: 65px;
          overflow: hidden;
          padding: 0 8px;
          transform: translate(0, 0);
          vertical-align: middle;
          width: 65px;

          img {
            display: block;
            height: auto;
            width: 100%;
          }
        }

        &__text {
          color: @darktext;
          display: inline-block;
          font-size: 16 * @rem;
          font-weight: 400;
          line-height: 1.8;
          padding-left: 20px;
          text-align: left;
          vertical-align: middle;
          width: calc(100% - 65px);

          a {
            font-weight: 700;
            text-decoration: none;
          }

          strong {
            color: @yellow;
          }

          @media @smartphone {
            line-height: 1.2;

            > a {
              display: inline-block;
              padding-bottom: 10px;
            }
          }
        }
      }

      &__content {
        margin: 0 auto;
        max-width: 1024px;
        padding: 0 10px;
      }

      &__footer {
        padding: 15px 0;
        text-align: center;

        .button {
          color: @lightgrey;
          display: none;
          font-size: 14 * @rem;
          font-weight: 700;
          line-height: 1;
          position: relative;
          text-decoration: none;

          .icon-fonts {
            margin-bottom: 5px;
            transition: all 500ms linear;
          }

          &:hover {
            .icon-fonts {
              transform: rotate(-360deg);
            }
          }
        }
      }

      &__title {
        color: @greybrown;
        font-family: 'Megafresh';
        font-size: 70 * @rem;
        font-weight: 400;

        @media @smartphone {
          font-size: 40 * @rem;
        }
      }
    }
  }
}
