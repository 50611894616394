/**
 * Revealable component styles
 */
.revealable {
  opacity: 0;

  &.is-revealed {
    opacity: 1;
    transition: opacity 1s;
  }
}
