
/**
 * Footer component styles
 */
.footer {
  background: @greybrown;
  clear: both;
  color: @white;
  min-height: 380px;
  padding: 55px 0 80px;
  position: relative;
  z-index: 20;

  @media @smartphone {
    margin-top: 80px;
    padding: 0 0 80px;
  }

  &__aside {
    clear: both;

    &--primary {
      display: block;
      padding: 0 20px;
      position: relative;
      text-align: center;

      a,
      span {
        font-size: 13 * @rem;
        text-decoration: none;
      }

      @media @smartphone {
        &:before {
          background: @white;
          content: '';
          height: 2px;
          left: 50%;
          margin-left: -24px;
          opacity: 0.2;
          position: absolute;
          top: -30px;
          width: 48px;
        }

        a,
        span {
          font-size: 12 * @rem;
          text-decoration: none;
        }
      }
    }

    &--secondary {
      display: block;
      padding-top: 20px;

      .list {
        text-align: center;

        .list__item {
          a,
          span {
            font-size: 12 * @rem;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__baseline {
    font-size: 13 * @rem;

    strong {
      color: @yellow;
      font-weight: 400;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1024px;
    @media (min-width: 768px) {
      display: inherit;
      flex-wrap: inherit;
      padding: inherit;
    }
  }

  &__follow-us {
    margin-bottom: 30px;
    padding-bottom: 30px;
    position: relative;

    @media @smartphone {
      margin-top: 30px;
      padding-top: 30px;
      text-align: center;

      &:before {
        background: @white;
        content: '';
        height: 2px;
        left: 50%;
        margin-left: -24px;
        opacity: 0.2;
        position: absolute;
        top: 0;
        width: 48px;
      }
    }

    &:after {
      background: @white;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      opacity: 0.2;
      position: absolute;
      width: 48px;

      @media @smartphone {
        left: 50%;
        margin-left: -24px;
      }
    }

    .list__item {
      margin-right: 30px;

      @media @smartphone {
        margin: 0 15px;
      }

      a {
        color: @white;
        text-decoration: none;
        transition: all 150ms linear;

        .icon-fonts {
          border: 2px solid @white;
          border-radius: 99px;
          font-size: 44 * @rem;
          transition: all 150ms linear;
        }

        &:hover {
          color: @greybrown;

          .icon-fonts {
            background: @white;
          }
        }
      }
    }
  }

  &__logo {
    float: left;
    min-height: 200px;
    position: relative;
    width: 290px;

    @media @tablet {
      width: 27%;
    }

    @media @smartphone {
      min-height: 1px;
      width: 100%;
    }

    a {
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      @media @smartphone {
        left: 0;
        margin: -47px auto 0;
        position: relative;
        top: 0;
        transform: translate(0, 0);
      }

      img {
        display: block;
        height: 100px;
        width: 100px;

        @media @smartphone {
          height: 70px;
          margin: 0 auto;
          width: 70px;
        }
      }
    }
  }

  &__main {
    display: block;
  }

  &__nav {
    float: left;
    width: 268px;

    @media @tablet {
      width: 32%;
    }

    @media @smartphone {
      padding-top: 25px;
      width: 100%;
    }

    .list {
      list-style: none;
      margin: 0;
      padding: 0;

      &__item {
        display: block;

        a {
          display: block;
          font-size: 12 * @rem;
          line-height: 1.2;
          margin-bottom: 20px;
          text-align: left;
          text-decoration: none;
          text-transform: uppercase;

          @media @smartphone {
            font-size: 16 * @rem;
            margin-bottom: 30px;
            text-align: center;
          }
        }
      }
    }
  }

  &__newsletter {
    @media @smartphone {
      text-align: center;
    }

    .button {
      background: @yellow;
      color: @black;
      display: inline-block;
      font-size: 14 * @rem;
      font-weight: 700;
      line-height: 47 * @rem;
      margin-top: 20px;
      min-height: 47px;
      min-width: 235px;
      text-align: center;
      text-decoration: none;
      transition: all 150ms linear;

      &:hover {
        background: @white;
        color: @yellow;
      }
    }
  }

  &__share {
    float: left;
    margin-bottom: 30px;
    padding-bottom: 30px;
    position: relative;
    width: 400px;

    @media @tablet {
      width: 41%;
    }

    @media @smartphone {
      width: 100%;
    }
  }

  &__title {
    color: @yellow;
    font-size: 13 * @rem;
    font-weight: 700;
    padding-bottom: 30px;
    text-transform: uppercase;

    @media @smartphone {
      font-size: 16 * @rem;
      text-align: center;
    }
  }
}
