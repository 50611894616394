@import './styles-overtopheader.less';

/**
OAS TO DFP - ENCART EMERGENCY
*/

body.has-coverPage {
  .privateDealContainer {
    display: none;
  }
}

.adunit {
  position: relative;
  z-index: 1;

  &.display-none {
    display: none;
  }

  &[data-adunit='multiBoard'] {
    padding-bottom: 15px;
      padding-top: 15px;
  }

  &[data-adunit='coverZone'] {
    display: none;

    &.display-block {
      display: block;

      &+.slider.sliderPrez {
        display: none;
      }
    }
  }
}

div#promotions {
  text-align: center;
}
