/**
 * Timed-title component styles
 */
.timed-title {
  opacity: 0;

  &.is-ready {
    opacity: 1;
    transition: opacity 0.3s;
  }
}
