@charset "UTF-8";
/**
 * Home page styles
 */
/**
 * Background-video component styles
 */
.background-video {
  background: url("/home/assets/images/book-your-hotel/background-desktop.jpg") 50% 50% no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .background-video {
    background-image: url("/home/assets/images/book-your-hotel/background-mobile.jpg");
  }
}
.background-video__poster,
.background-video__source {
  font-family: "object-fit: cover";
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.background-video__source {
  opacity: 0;
  transition: opacity 1s linear;
}

.is-ready .background-video__source {
  opacity: 1;
}

.background-video__play-pause {
  background: #fff;
  border-radius: 100px;
  bottom: 1.25rem;
  display: block;
  height: 2.5rem;
  left: 1.25rem;
  position: absolute;
  width: 2.5rem;
  z-index: 10;
}

.background-video__play-pause:after {
  border-bottom: 0.8rem solid transparent;
  border-left: 1rem solid #000;
  border-top: 0.8rem solid transparent;
  content: "";
  left: 54%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 150ms linear;
}

.background-video__play-pause:before {
  border-bottom: 0;
  border-left: 0.3rem solid #000;
  border-right: 0.3rem solid #000;
  border-top: 0;
  content: "";
  height: 1.4rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 150ms linear;
  width: 1rem;
}

.background-video__play-pause.paused:after {
  opacity: 1;
}

.background-video__play-pause.paused:before {
  height: 0;
  width: 0;
}

/**
 * Embedded-video component styles
 */
.embedded-video__close {
  background: #000;
  border: 2px solid #fff;
  border-radius: 99px;
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  height: 1.875rem;
  line-height: 1.625rem;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  transition: all 300ms linear;
  width: 1.875rem;
  z-index: 2;
}

.embedded-video__close:after {
  content: "×";
}

.embedded-video__close:hover,
.embedded-video__close:focus {
  background: rgba(255, 255, 255, 0.4);
}

.embedded-video__open {
  background: none;
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.embedded-video__open:focus {
  border: 5px solid #f8aa00;
}

.embedded-video__open:after {
  border: 2px solid #fff;
  content: "";
  height: 80px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 100ms linear;
  width: 80px;
}

:hover.embedded-video__open:after,
:focus.embedded-video__open:after {
  transform: translate(-50%, -50%) scale(1.1);
}

.embedded-video__open:before {
  border-bottom: 20px solid transparent;
  border-left: 33px solid #fff;
  border-top: 20px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 100ms linear;
  width: 0;
}

.embedded-video__poster {
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: block;
  height: 380px;
  position: absolute;
  right: 20px;
  top: 210px;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  width: 380px;
}

@media only screen and (max-width: 1023px) {
  .embedded-video__poster {
    margin: 0 auto;
    position: relative;
    top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .embedded-video__poster {
    height: 300px;
    right: 0;
    width: 300px;
  }
}
.embedded-video__source {
  background: #000;
  bottom: 0;
  height: calc(100% - 60px);
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 60px;
  transform: scale(0, 0);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, visibility 0s ease-in-out 0.25s;
  visibility: hidden;
  width: 100%;
  z-index: 10;
}

.embedded-video__source video {
  display: block;
  font-family: "object-fit: contain";
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.is-playing .embedded-video__source {
  opacity: 1;
  transform: scale(1, 1);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, visibility 0s ease-in-out 0s;
  visibility: visible;
}

.embedded-video__content {
  height: 100%;
}

/**
 * Footer component styles
 */
.footer {
  background: #574b4a;
  clear: both;
  color: #fff;
  min-height: 380px;
  padding: 55px 0 80px;
  position: relative;
  z-index: 20;
}

@media only screen and (max-width: 767px) {
  .footer {
    margin-top: 80px;
    padding: 0 0 80px;
  }
}
.footer__aside {
  clear: both;
}

.footer__aside--primary {
  display: block;
  padding: 0 20px;
  position: relative;
  text-align: center;
}

.footer__aside--primary a,
.footer__aside--primary span {
  font-size: 0.8125rem;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .footer__aside--primary:before {
    background: #fff;
    content: "";
    height: 2px;
    left: 50%;
    margin-left: -24px;
    opacity: 0.2;
    position: absolute;
    top: -30px;
    width: 48px;
  }

  .footer__aside--primary a,
.footer__aside--primary span {
    font-size: 0.75rem;
    text-decoration: none;
  }
}
.footer__aside--secondary {
  display: block;
  padding-top: 20px;
}

.footer__aside--secondary .list {
  text-align: center;
}

.footer__aside--secondary .list .list__item a,
.footer__aside--secondary .list .list__item span {
  font-size: 0.75rem;
  text-decoration: none;
}

.footer__baseline {
  font-size: 0.8125rem;
}

.footer__baseline strong {
  color: #f8aa00;
  font-weight: 400;
}

.footer__content {
  margin: 0 auto;
  max-width: 1024px;
}

@media (min-width: 768px) {
  .footer__content {
    display: inherit;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    padding: inherit;
  }
}
.footer__follow-us {
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .footer__follow-us {
    margin-top: 30px;
    padding-top: 30px;
    text-align: center;
  }

  .footer__follow-us:before {
    background: #fff;
    content: "";
    height: 2px;
    left: 50%;
    margin-left: -24px;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 48px;
  }
}
.footer__follow-us:after {
  background: #fff;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  opacity: 0.2;
  position: absolute;
  width: 48px;
}

@media only screen and (max-width: 767px) {
  .footer__follow-us:after {
    left: 50%;
    margin-left: -24px;
  }
}
.footer__follow-us .list__item {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .footer__follow-us .list__item {
    margin: 0 15px;
  }
}
.footer__follow-us .list__item a {
  color: #fff;
  text-decoration: none;
  transition: all 150ms linear;
}

.footer__follow-us .list__item a .icon-fonts {
  border: 2px solid #fff;
  border-radius: 99px;
  font-size: 2.75rem;
  transition: all 150ms linear;
}

.footer__follow-us .list__item a:hover {
  color: #574b4a;
}

.footer__follow-us .list__item a:hover .icon-fonts {
  background: #fff;
}

.footer__logo {
  float: left;
  min-height: 200px;
  position: relative;
  width: 290px;
}

@media only screen and (max-width: 1023px) {
  .footer__logo {
    width: 27%;
  }
}
@media only screen and (max-width: 767px) {
  .footer__logo {
    min-height: 1px;
    width: 100%;
  }
}
.footer__logo a {
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 767px) {
  .footer__logo a {
    left: 0;
    margin: -47px auto 0;
    position: relative;
    top: 0;
    transform: translate(0, 0);
  }
}
.footer__logo a img {
  display: block;
  height: 100px;
  width: 100px;
}

@media only screen and (max-width: 767px) {
  .footer__logo a img {
    height: 70px;
    margin: 0 auto;
    width: 70px;
  }
}
.footer__main {
  display: block;
}

.footer__nav {
  float: left;
  width: 268px;
}

@media only screen and (max-width: 1023px) {
  .footer__nav {
    width: 32%;
  }
}
@media only screen and (max-width: 767px) {
  .footer__nav {
    padding-top: 25px;
    width: 100%;
  }
}
.footer__nav .list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__nav .list__item {
  display: block;
}

.footer__nav .list__item a {
  display: block;
  font-size: 0.75rem;
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .footer__nav .list__item a {
    font-size: 1rem;
    margin-bottom: 30px;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .footer__newsletter {
    text-align: center;
  }
}
.footer__newsletter .button {
  background: #f8aa00;
  color: #000;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 2.9375rem;
  margin-top: 20px;
  min-height: 47px;
  min-width: 235px;
  text-align: center;
  text-decoration: none;
  transition: all 150ms linear;
}

.footer__newsletter .button:hover {
  background: #fff;
  color: #f8aa00;
}

.footer__share {
  float: left;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
  width: 400px;
}

@media only screen and (max-width: 1023px) {
  .footer__share {
    width: 41%;
  }
}
@media only screen and (max-width: 767px) {
  .footer__share {
    width: 100%;
  }
}
.footer__title {
  color: #f8aa00;
  font-size: 0.8125rem;
  font-weight: 700;
  padding-bottom: 30px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .footer__title {
    font-size: 1rem;
    text-align: center;
  }
}
/**
 * Gallery component styles
 */
.gallery {
  font-size: 0;
  padding-top: 40px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .gallery {
    margin: 0 -10px;
  }
}
.gallery__button {
  background: none;
  border: 0;
  color: #7f818a;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  margin: 15px auto;
  opacity: 0;
  position: relative;
  text-decoration: none;
  transition: opacity 0.3s;
}

.has-next .gallery__button {
  opacity: 1;
}

.gallery__button .icon-fonts {
  margin-bottom: 5px;
  transition: transform 500ms linear;
}

.gallery__button:hover.gallery__button .icon-fonts {
  transform: rotate(-360deg);
}

.gallery__image {
  display: block;
  font-family: "object-fit: cover";
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.gallery__item {
  display: inline-block;
  height: 180px;
  margin: 10px;
  position: relative;
  width: 180px;
}

@media only screen and (max-width: 767px) {
  .gallery__item {
    margin: 5px;
    max-width: calc(50% - 20px);
  }
}
.gallery__item:before {
  color: #fff;
  font-size: 2.1875rem;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  z-index: 10;
}

.section--join-our-community-grey-background {
  background: #f8f8f8;
  padding-top: 50px;
}

.section--join-our-community .section__baseline {
  font-size: 0;
  margin: 0 auto;
  width: 400px;
}

.section--join-our-community .section__baseline .emoji {
  display: inline-block;
  height: auto;
  margin-left: 3px;
  vertical-align: middle;
  width: 16px;
}

@media only screen and (max-width: 767px) {
  .section--join-our-community .section__baseline {
    max-width: 440px;
    width: 100%;
  }
}
.section--join-our-community .section__baseline__logo {
  background: #f8aa00;
  border-radius: 99px;
  display: inline-block;
  height: 65px;
  overflow: hidden;
  padding: 0 8px;
  transform: translate(0, 0);
  vertical-align: middle;
  width: 65px;
}

.section--join-our-community .section__baseline__logo img {
  display: block;
  height: auto;
  width: 100%;
}

.section--join-our-community .section__baseline__text {
  color: #130c0e;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  padding-left: 20px;
  text-align: left;
  vertical-align: middle;
  width: calc(100% - 65px);
}

.section--join-our-community .section__baseline__text a {
  font-weight: 700;
  text-decoration: none;
}

.section--join-our-community .section__baseline__text strong {
  color: #f8aa00;
}

@media only screen and (max-width: 767px) {
  .section--join-our-community .section__baseline__text {
    line-height: 1.2;
  }

  .section--join-our-community .section__baseline__text > a {
    display: inline-block;
    padding-bottom: 10px;
  }
}
.section--join-our-community .section__content {
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 10px;
}

.section--join-our-community .section__footer {
  padding: 15px 0;
  text-align: center;
}

.section--join-our-community .section__footer .button {
  color: #7f818a;
  display: none;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  position: relative;
  text-decoration: none;
}

.section--join-our-community .section__footer .button .icon-fonts {
  margin-bottom: 5px;
  transition: all 500ms linear;
}

.section--join-our-community .section__footer .button:hover .icon-fonts {
  transform: rotate(-360deg);
}

.section--join-our-community .section__title {
  color: #574b4a;
  font-family: "Megafresh";
  font-size: 4.375rem;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .section--join-our-community .section__title {
    font-size: 2.5rem;
  }
}
/**
 * Nav-map component styles
 */
.nav-map {
  font-size: 0;
  margin: 0 auto;
  max-width: 1024px;
  padding-top: 40px;
  text-align: center;
}

.nav-map__area {
  display: inline-block;
  height: 459px;
  margin-right: 77px;
  position: relative;
  vertical-align: top;
  width: 452px;
}

@media only screen and (max-width: 1023px) {
  .nav-map__area {
    margin-bottom: -80px;
    margin-right: 0;
    transform: scale(0.8);
    transform-origin: 50% 0;
  }
}
@media only screen and (max-width: 767px) {
  .nav-map__area {
    display: block;
    left: 50%;
    margin: 0 -226px -180px;
    position: relative;
    transform: scale(0.6);
  }
}
.nav-map__img {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.nav-map__img--invisible {
  opacity: 0;
  z-index: 20;
}

.nav-map__img--partial {
  opacity: 0;
  transition: all linear 150ms;
  z-index: 10;
}

.nav-map__img--partial.visible,
.nav-map__img--partial.selected {
  opacity: 1;
}

.nav-map__img--partial.hauts-de-france {
  left: 217px;
  top: 0;
}

.nav-map__img--partial.normandie {
  left: 105px;
  top: 54px;
}

.nav-map__img--partial.bretagne {
  left: 0;
  top: 110px;
}

.nav-map__img--partial.grand-est {
  left: 287px;
  top: 46px;
}

.nav-map__img--partial.ile-de-france {
  left: 219px;
  top: 97px;
}

.nav-map__img--partial.pays-de-la-loire {
  left: 74px;
  top: 131px;
}

.nav-map__img--partial.centre-val-de-loire {
  left: 168px;
  top: 112px;
}

.nav-map__img--partial.bourgogne-franche-comte {
  left: 269px;
  top: 141px;
}

.nav-map__img--partial.nouvelle-aquitaine {
  left: 93px;
  top: 205px;
}

.nav-map__img--partial.auvergne-rhone-alpes {
  left: 239px;
  top: 225px;
}

.nav-map__img--partial.occitanie {
  left: 146px;
  top: 318px;
}

.nav-map__img--partial.paca {
  left: 322px;
  top: 310px;
}

.nav-map__nav {
  display: inline-block;
  margin-left: 77px;
  padding-top: 20px;
  vertical-align: top;
  width: 248px;
}

@media only screen and (max-width: 1023px) {
  .nav-map__nav {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .nav-map__nav {
    display: none;
  }
}
.nav-map__nav .list {
  display: block;
}

.nav-map__nav .list__item {
  display: block;
}

.nav-map__nav .list__item a {
  color: #130c0e;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 27px;
  padding: 0 20px;
  text-align: left;
  text-decoration: none;
  transition: all 150ms linear;
}

.nav-map__nav .list__item a:hover,
.nav-map__nav .list__item a.hover,
.nav-map__nav .list__item a.selected {
  background: #f8aa00;
  color: #fff;
}

.nav-map__mobile-nav {
  display: none;
}

@media only screen and (max-width: 767px) {
  .nav-map__mobile-nav {
    display: inline-block;
    font-size: 0.75rem;
    height: 50px;
    margin-top: 30px;
    position: relative;
    width: 232px;
  }

  .nav-map__mobile-nav select {
    height: 50px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 232px;
    z-index: 10;
  }

  .nav-map__mobile-nav .select-label {
    border: 2px solid #f8aa00;
    color: #130c0e;
    font-size: 0.875rem;
    height: 50px;
    left: 0;
    line-height: 2.875rem;
    position: absolute;
    text-align: center;
    top: 0;
    width: 232px;
  }

  .nav-map__mobile-nav .select-label:after {
    border-right: 2px solid #7f818a;
    border-top: 2px solid #7f818a;
    content: "";
    height: 12px;
    position: absolute;
    right: 20px;
    top: 14px;
    transform: rotate(135deg);
    width: 12px;
  }
}
/**
 * Revealable component styles
 */
.revealable {
  opacity: 0;
}

.revealable.is-revealed {
  opacity: 1;
  transition: opacity 1s;
}

/**
 * Slider component styles
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slider__item {
  display: block;
  text-decoration: none;
}

.slider__item__background,
.slider__item__badge,
.slider__item__content,
.slider__item__cta,
.slider__item__inner {
  display: block;
}

.slider .slick-dots {
  bottom: -28px;
  display: block;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.slider .slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 11px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 11px;
}

.slider .slick-dots li button {
  background: transparent;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 11px;
  line-height: 0;
  padding: 5px;
  width: 11px;
}

.slider .slick-dots li button:before {
  background: #dbdcdf;
  border-radius: 100%;
  content: "";
  height: 11px;
  left: 0;
  line-height: 1.25rem;
  position: absolute;
  text-align: center;
  top: 0;
  width: 11px;
}

.slider--best-offers .foo {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}

.slider--best-offers .slick-dots {
  bottom: 0;
}

.slider--best-offers .slick-dots li button:before {
  background: #24abab;
  border-radius: 99px;
  transition: all 150ms linear;
}

.slider--best-offers .slick-dots li.slick-active {
  width: 22px;
}

.slider--best-offers .slick-dots li.slick-active button {
  width: 22px;
}

.slider--best-offers .slick-dots li.slick-active button:before {
  width: 22px;
}

@media only screen and (max-width: 767px) {
  .slider--best-offers .slick-list {
    overflow: visible;
  }
}
.slider--best-offers .slider__button {
  border: 2px solid #24abab;
  color: #24abab;
  display: inline-block;
  font-size: 0;
  line-height: 2.6875rem;
  min-height: 47px;
  text-align: center;
  text-decoration: none;
  transition: all 150ms linear;
  width: 47px;
}

.slider--best-offers .slider__button:hover {
  background: #24abab;
  color: #fff;
}

.slider--best-offers .slider__button--next {
  margin-left: -2px;
}

.slider--best-offers .slider__button--next:before {
  content: "→";
  font-size: 1.0625rem;
}

.slider--best-offers .slider__button--prev:before {
  content: "←";
  font-size: 1.0625rem;
}

@media only screen and (max-width: 767px) {
  .slider--best-offers .slider__infos {
    background: #fff;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
    display: block;
    padding: 20px;
  }
}
.slider--best-offers .slider__item {
  min-width: 714px;
  padding: 35px 34px 50px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .slider--best-offers .slider__item {
    max-width: 256px;
    min-width: 200px;
    padding: 0 5px;
  }
}
.slider--best-offers .slider__item__background {
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.slider--best-offers .slider__item__background:after {
  background: rgba(0, 0, 0, 0.35);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.slider--best-offers .slider__item__baseline {
  display: block;
  font-size: 1rem;
  font-weight: 700;
  margin: 0 auto;
  max-width: 600px;
  padding: 40px 20px 0;
}

@media only screen and (max-width: 767px) {
  .slider--best-offers .slider__item__baseline {
    color: #130c0e;
    min-height: 70px;
    padding: 0;
  }
}
.slider--best-offers .slider__item__content {
  color: #fff;
  left: 0;
  padding: 50px 34px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .slider--best-offers .slider__item__content {
    left: 0;
    padding: 0;
    position: relative;
    top: 0;
    transform: translate(0, 0);
  }
}
.slider--best-offers .slider__item__cta .button {
  border: 0;
  border-color: #fff;
  display: inline-block;
  font-family: "Lato";
  font-size: 0.875rem;
  line-height: 2.6875rem;
  margin-top: 40px;
  min-height: 47px;
  min-width: 180px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .slider--best-offers .slider__item__cta .button {
    border-color: #7f818a;
    color: #7f818a;
    margin-top: 0;
  }
}
.slider--best-offers .slider__item__cta .button:after {
  border: 2px solid #fff;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 400ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .slider--best-offers .slider__item__cta .button:after {
    border-color: #7f818a;
  }
}
.slider--best-offers .slider__item__cta .button:hover:after {
  transform: scale(1.1);
}

.slider--best-offers .slider__item__title {
  display: block;
  font-family: "Megafresh";
  font-size: 3.125rem;
  font-weight: 400;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .slider--best-offers .slider__item__title {
    font-size: 2.5rem;
    height: 159px;
    margin: -159px 0 0;
    padding: 0 5px;
    position: relative;
  }

  .slider--best-offers .slider__item__title span {
    display: block;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.slider--exclus .slick-dots {
  bottom: 0;
}

.slider--exclus .slick-dots li button:before {
  background: #e92d8e;
  border-radius: 99px;
  transition: all 150ms linear;
}

.slider--exclus .slick-dots li.slick-active {
  width: 22px;
}

.slider--exclus .slick-dots li.slick-active button {
  width: 22px;
}

.slider--exclus .slick-dots li.slick-active button:before {
  width: 22px;
}

@media only screen and (max-width: 767px) {
  .slider--exclus .slick-list {
    overflow: visible;
  }
}
.slider--exclus .slider__button {
  border: 2px solid #e92d8e;
  color: #e92d8e;
  display: inline-block;
  font-size: 0;
  line-height: 2.6875rem;
  margin: 0 10px;
  min-height: 47px;
  text-align: center;
  text-decoration: none;
  transition: all 150ms linear;
  width: 47px;
}

.slider--exclus .slider__button:hover {
  background: #e92d8e;
  color: #fff;
}

.slider--exclus .slider__button--next {
  margin-left: -2px;
}

.slider--exclus .slider__button--next:before {
  content: "→";
  font-size: 1.0625rem;
}

.slider--exclus .slider__button--prev:before {
  content: "←";
  font-size: 1.0625rem;
}

@media only screen and (max-width: 767px) {
  .slider--exclus .slider__infos {
    background: #fff;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
    display: block;
    padding: 20px;
  }
}
.slider--exclus .slider__item {
  min-width: 714px;
  padding: 35px 34px 50px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .slider--exclus .slider__item {
    max-width: 256px;
    min-width: 200px;
    padding: 0 5px;
  }
}
.slider--exclus .slider__item__background {
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.slider--exclus .slider__item__baseline {
  display: block;
  font-size: 1rem;
  font-weight: 700;
  margin: 0 auto;
  max-width: 600px;
  padding: 40px 20px 0;
}

@media only screen and (max-width: 767px) {
  .slider--exclus .slider__item__baseline {
    color: #130c0e;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    min-height: 70px;
    padding: 0;
    text-align: justify;
  }
}
.slider--exclus .slider__item__content {
  color: #fff;
  left: 0;
  padding: 50px 34px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .slider--exclus .slider__item__content {
    left: 0;
    padding: 0;
    position: relative;
    top: 0;
    transform: translate(0, 0);
  }
}
.slider--exclus .slider__item__cta .button {
  border: 0;
  border-color: #fff;
  display: inline-block;
  font-family: "Lato";
  font-size: 0.875rem;
  line-height: 2.6875rem;
  margin-top: 40px;
  min-height: 47px;
  min-width: 180px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .slider--exclus .slider__item__cta .button {
    border-color: #7f818a;
    color: #7f818a;
    margin-top: 0;
  }
}
.slider--exclus .slider__item__cta .button:after {
  border: 2px solid #fff;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 400ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .slider--exclus .slider__item__cta .button:after {
    border-color: #7f818a;
  }
}
.slider--exclus .slider__item__cta .button:hover:after {
  transform: scale(1.1);
}

.slider--exclus .slider__item__title {
  display: block;
  font-family: "Megafresh";
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .slider--exclus .slider__item__title {
    font-size: 2.5rem;
    height: 159px;
    margin: -159px 0 0;
    padding: 0 5px;
    position: relative;
  }

  .slider--exclus .slider__item__title span {
    display: block;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.slider--inspirations .foo {
  display: none;
}

.slider--inspirations[data-role=slider-a11y] .slick-track {
  left: -280px;
}

@media only screen and (max-width: 1023px) {
  .slider--inspirations[data-role=slider-a11y] .slick-track {
    left: -230px;
  }
}
@media only screen and (max-width: 599px) {
  .slider--inspirations[data-role=slider-a11y] .slick-track {
    left: 0;
  }
}
.slider--inspirations .slider__button {
  border: 2px solid #574b4a;
  color: #574b4a;
  display: inline-block;
  font-size: 0;
  line-height: 2.5625rem;
  margin-right: 20px;
  min-height: 45px;
  text-align: center;
  text-decoration: none;
  transition: all 150ms linear;
  width: 45px;
}

.slider--inspirations .slider__button:hover {
  background: #574b4a;
  color: #fff;
}

.slider--inspirations .slider__button--next {
  margin-left: -2px;
}

.slider--inspirations .slider__button--next:before {
  content: "→";
  font-size: 1.0625rem;
}

.slider--inspirations .slider__button--prev:before {
  content: "←";
  font-size: 1.0625rem;
}

.slider--inspirations .slider__content--main {
  left: 422px;
  min-width: 847px;
  position: relative;
  z-index: 100;
}

@media only screen and (max-width: 1023px) {
  .slider--inspirations .slider__content--main {
    left: 382px;
  }
}
@media only screen and (max-width: 767px) {
  .slider--inspirations .slider__content--main {
    left: 0;
    min-width: 300px;
    padding: 0 10px 60px;
  }

  .slider--inspirations .slider__content--main .slick-list {
    overflow: visible;
  }
}
.slider--inspirations .slider__content--main .slider__item {
  display: block;
  min-width: 467px;
  opacity: 0.3;
  padding-bottom: 50px;
  position: relative;
  transition: all 300ms linear;
}

@media only screen and (max-width: 1023px) {
  .slider--inspirations .slider__content--main .slider__item {
    min-width: 390px;
  }
}
@media only screen and (max-width: 767px) {
  .slider--inspirations .slider__content--main .slider__item {
    max-width: 143px;
    min-width: 143px;
    opacity: 1;
    padding: 0 5px;
  }
}
.slider--inspirations .slider__content--main .slider__item.slick-current {
  opacity: 1;
}

.slider--inspirations .slider__content--main .slider__item__background {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.slider--inspirations .slider__content--main .slider__item__background img {
  display: block;
  font-family: "object-fit: cover";
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.slider--inspirations .slider__content--main .slider__item__content {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
}

.slider--inspirations .slider__content--main .slider__item__cta {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .slider--inspirations .slider__content--main .slider__item__cta {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.slider--inspirations .slider__content--main .slider__item__cta .button {
  border: 0;
  color: #fff;
  display: inline-block;
  font-family: "Lato";
  font-size: 0.875rem;
  margin-top: 30px;
  min-width: 154px;
  padding: 12px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.slider--inspirations .slider__content--main .slider__item__cta .button:after {
  border: 2px solid #fff;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 400ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  width: 100%;
}

.slider--inspirations .slider__content--main .slider__item__cta .button:hover:after {
  transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
  .slider--inspirations .slider__content--main .slider__item__cta .button {
    color: transparent;
    min-width: 100px;
  }

  .slider--inspirations .slider__content--main .slider__item__cta .button:after {
    display: none;
  }
}
.slider--inspirations .slider__content--main .slider__item__inner {
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
  height: 380px;
  position: relative;
  width: 380px;
}

@media only screen and (max-width: 767px) {
  .slider--inspirations .slider__content--main .slider__item__inner {
    height: 236px;
    width: 100%;
  }
}
.slider--inspirations .slider__content--main .slider__item__title {
  color: #fff;
  display: block;
  font-family: "Megafresh";
  font-size: 3.75rem;
  line-height: 1;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .slider--inspirations .slider__content--main .slider__item__title {
    font-size: 2.5rem;
    padding: 0 10px;
  }
}
.slider--inspirations .slider__content--synced {
  left: 222px;
  margin-top: -530px;
  min-width: 1247px;
  overflow: hidden;
  padding: 100px 0 100px 200px;
  position: relative;
  z-index: 10;
}

@media only screen and (max-width: 1023px) {
  .slider--inspirations .slider__content--synced {
    left: 182px;
  }
}
@media only screen and (max-width: 767px) {
  .slider--inspirations .slider__content--synced {
    display: none;
  }
}
.slider--inspirations .slider__content--synced .slick-list {
  overflow: visible;
}

.slider--inspirations .slider__content--synced .slider__item {
  height: 380px;
  min-width: 467px;
  opacity: 0;
  position: relative;
  transition: all 300ms linear;
}

@media only screen and (max-width: 1023px) {
  .slider--inspirations .slider__content--synced .slider__item {
    min-width: 390px;
  }
}
.slider--inspirations .slider__content--synced .slider__item.slick-current {
  opacity: 1;
}

.slider--inspirations .slider__content--synced .slider__visuel-left {
  left: -182px;
  position: absolute;
  top: -100px;
}

.slider--inspirations .slider__content--synced .slider__visuel-right {
  bottom: -100px;
  position: absolute;
  right: 30px;
}

.slider--itineraries .slick-list {
  overflow: visible;
}

.slider--itineraries .foo {
  display: none;
}

.slider--itineraries .slider__button {
  border: 2px solid #e92d8e;
  color: #e92d8e;
  display: inline-block;
  font-size: 0;
  line-height: 2.6875rem;
  min-height: 47px;
  text-align: center;
  text-decoration: none;
  transition: all 150ms linear;
  width: 47px;
}

.slider--itineraries .slider__button:hover {
  background: #e92d8e;
  color: #fff;
}

.slider--itineraries .slider__button--next {
  margin-left: -2px;
}

.slider--itineraries .slider__button--next:before {
  content: "→";
  font-size: 1.0625rem;
}

.slider--itineraries .slider__button--prev:before {
  content: "←";
  font-size: 1.0625rem;
}

.slider--itineraries .slider__content {
  margin-top: 110px;
  position: relative;
  z-index: 100;
}

@media only screen and (max-width: 767px) {
  .slider--itineraries .slider__content {
    margin-top: 0;
    padding: 0 10px;
  }
}
.slider--itineraries .slider__item {
  margin: 0 25px;
  min-height: 200px;
  position: relative;
  width: 200px;
}

@media only screen and (max-width: 1023px) {
  .slider--itineraries .slider__item {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
    height: 230px;
    margin: 0 17px;
    min-width: 133px;
    width: 133px;
  }
}
@media only screen and (max-width: 767px) {
  .slider--itineraries .slider__item {
    height: 260px;
    margin: 0 5px;
  }
}
.slider--itineraries .slider__item:hover .slider__item__background,
.slider--itineraries .slider__item:focus .slider__item__background {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  transform: translate(0, -75%);
}

@media only screen and (max-width: 1023px) {
  .slider--itineraries .slider__item:hover .slider__item__background,
.slider--itineraries .slider__item:focus .slider__item__background {
    box-shadow: none;
    transform: translate(0, 0);
  }
}
.slider--itineraries .slider__item__background {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: -200px;
  position: relative;
  transition: all 200ms linear;
  z-index: 2;
}

@media only screen and (max-width: 1023px) {
  .slider--itineraries .slider__item__background {
    box-shadow: none;
    margin-bottom: 0;
  }
}
.slider--itineraries .slider__item__background img {
  display: block;
  height: auto;
  position: relative;
  width: 100%;
  z-index: 1;
}

.slider--itineraries .slider__item__badge {
  border-radius: 3px;
  bottom: 20px;
  color: #fff;
  display: block;
  font-family: "Lato";
  font-size: 0.625rem;
  left: 20px;
  padding: 5px;
  position: absolute;
  z-index: 2;
}

.slider--itineraries .slider__item__badge.festival-guide {
  color: #000;
}

@media only screen and (max-width: 1023px) {
  .slider--itineraries .slider__item__badge {
    bottom: 10px;
    left: 10px;
  }
}
.slider--itineraries .slider__item__img-title {
  bottom: 0;
  color: #fff;
  font-family: "Manifoldcf";
  font-size: 2.25rem;
  font-weight: 300;
  left: 0;
  line-height: 1;
  padding: 20px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.slider--itineraries .slider__item__baseline {
  color: #130c0e;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  overflow: hidden;
  padding: 0 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slider--itineraries .slider__item__baseline--with-wrap {
  white-space: normal;
}

@media only screen and (max-width: 1023px) {
  .slider--itineraries .slider__item__baseline {
    font-size: 0.75rem;
    padding: 0 10px 30px;
  }
}
.slider--itineraries .slider__item__content {
  height: 100%;
  left: 0;
  padding-top: 50px;
  top: 0;
  width: 100%;
  z-index: 1;
}

.slider--itineraries .slider__item__content--no-title {
  padding-top: 60px;
}

@media only screen and (max-width: 1023px) {
  .slider--itineraries .slider__item__content {
    height: auto;
    padding-top: 0;
    position: static;
  }
}
.slider--itineraries .slider__item__cta {
  padding-top: 18px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .slider--itineraries .slider__item__cta {
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }
}
.slider--itineraries .slider__item__cta .button {
  border: 2px solid #130c0e;
  color: #130c0e;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.3;
  max-width: 90%;
  min-width: 160px;
  padding: 5px 10px;
  text-decoration: none;
  transition: all 300ms linear;
}

.slider--itineraries .slider__item__cta .button:hover {
  background: #130c0e;
  color: #fff;
}

.slider--itineraries .slider__item__title {
  color: #130c0e;
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 16px;
  overflow: hidden;
  padding: 0 20px 14px;
}

@media only screen and (max-width: 1023px) {
  .slider--itineraries .slider__item__title {
    font-size: 1rem;
    margin: 0;
    padding: 10px;
  }
}
.slider--our-rooms .slick-list {
  overflow: visible;
}

.slider--our-rooms .slider__button {
  border: 2px solid #574b4a;
  color: #574b4a;
  display: inline-block;
  font-size: 0;
  line-height: 2.6875rem;
  margin: 0 10px;
  min-height: 47px;
  text-align: center;
  text-decoration: none;
  transition: all 150ms linear;
  width: 47px;
}

.slider--our-rooms .slider__button:hover {
  background: #574b4a;
  color: #fff;
}

.slider--our-rooms .slider__button--next:before {
  content: "→";
  font-size: 1.0625rem;
}

.slider--our-rooms .slider__button--prev:before {
  content: "←";
  font-size: 1.0625rem;
}

.slider--our-rooms .slider__content {
  margin-top: 30px;
  position: relative;
  z-index: 100;
}

@media only screen and (max-width: 767px) {
  .slider--our-rooms .slider__content {
    margin-top: 0;
    padding: 0 10px;
  }
}
.slider--our-rooms .slider__item {
  height: 464px;
  margin: 0 25px;
  position: relative;
  width: 464px;
}

@media only screen and (max-width: 767px) {
  .slider--our-rooms .slider__item:before {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 26%, rgba(252, 252, 252, 0) 27%, rgba(0, 0, 0, 0.3) 100%);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
.slider--our-rooms .slider__item__mobile-cta {
  display: none;
}

@media only screen and (max-width: 767px) {
  .slider--our-rooms .slider__item__mobile-cta {
    bottom: 10px;
    display: block;
    left: 10px;
    position: absolute;
    text-align: center;
    width: calc(100% - 20px);
  }

  .slider--our-rooms .slider__item__mobile-cta .button {
    border: 2px solid #fff;
    color: #fff;
    display: block;
    font-size: 0.75rem;
    line-height: 1.9375rem;
    text-align: center;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .slider--our-rooms .slider__item {
    width: 133px;
  }
}
.slider--our-rooms .slider__item[data-slug=tandem] {
  background: #dbceae;
  color: #574b4a;
}

.slider--our-rooms .slider__item[data-slug=side-car] {
  background: #dd2465;
  color: #fff;
}

.slider--our-rooms .slider__item[data-slug=break] {
  background: #f59c19;
  color: #fff;
}

.slider--our-rooms .slider__item[data-slug=cabrio] {
  background: #00747a;
  color: #fff;
}

.slider--our-rooms .slider__item[data-slug=combi] {
  background: #2db5b4;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .slider--our-rooms .slider__item {
    height: 260px;
    margin: 0 5px;
  }
}
.slider--our-rooms .slider__item__room {
  color: #000;
  display: block;
  font-family: "Manifoldcf";
  font-size: 0.9375rem;
  font-weight: 900;
  left: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 10px;
  width: 100%;
  z-index: 10;
}

.slider--our-rooms .slider__item__cat {
  color: #fff;
  display: block;
  font-family: "Megafresh";
  font-size: 2.9375rem;
  font-weight: 400;
  position: absolute;
  text-align: center;
  top: 30px;
  width: 100%;
  z-index: 20;
}

@media only screen and (max-width: 767px) {
  .slider--our-rooms .slider__item__cat {
    font-size: 1.875rem;
  }
}
.slider--our-rooms .slider__item__background {
  margin-top: 50px;
  position: relative;
  text-align: center;
  transition: all 200ms linear;
  z-index: 2;
}

@media only screen and (max-width: 1023px) {
  .slider--our-rooms .slider__item__background {
    box-shadow: none;
  }
}
@media only screen and (max-width: 767px) {
  .slider--our-rooms .slider__item__background {
    margin-top: 90px;
  }
}
.slider--our-rooms .slider__item__background img {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 90%;
  position: relative;
  width: 250px;
  z-index: 1;
}

.slider--our-rooms .slider__item__baseline {
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 20px 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slider--our-rooms .slider__item__baseline--with-wrap {
  white-space: normal;
}

.slider--our-rooms .slider__item__content {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .slider--our-rooms .slider__item__content {
    display: none;
  }
}
.slider--our-rooms .slider__item__cta {
  padding-top: 18px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .slider--our-rooms .slider__item__cta {
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }
}
.slider--our-rooms .slider__item__cta .button {
  border: 2px solid #7f818a;
  color: #7f818a;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.3;
  max-width: 90%;
  min-width: 160px;
  padding: 5px 10px;
  text-decoration: none;
  transition: all 300ms linear;
}

.slider--our-rooms .slider__item__cta .button:hover {
  background: #7f818a;
  color: #fff;
}

.slider--our-rooms .slider__item__title {
  color: #7f818a;
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 16px;
  overflow: hidden;
  padding: 0 20px 14px;
}

@media only screen and (max-width: 1023px) {
  .slider--our-rooms .slider__item__title {
    font-size: 16px;
    margin: 0;
    padding: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .slider--on-the-road:after,
.slider--on-the-road:before {
    color: #7f818a;
    font-size: 1rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
  }

  .slider--on-the-road:after {
    content: "→";
    right: 20px;
  }

  .slider--on-the-road:before {
    content: "←";
    left: 20px;
  }
}
.slider--on-the-road .slick-dots {
  bottom: 100px;
}

.slider--on-the-road .slick-dots li button:before {
  background: #f8aa00;
  border-radius: 99px;
  transition: width 150ms linear;
}

.slider--on-the-road .slick-dots li.slick-active {
  width: 20px;
}

.slider--on-the-road .slick-dots li.slick-active button {
  width: 20px;
}

.slider--on-the-road .slick-dots li.slick-active button:before {
  width: 20px;
}

.slider--on-the-road .slider__item {
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .slider--on-the-road .slider__item {
    width: 300px;
  }
}
.slider--on-the-road .slider__item__background {
  position: absolute;
  right: 20px;
  top: 0;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .slider--on-the-road .slider__item__background {
    display: none;
  }
}
.slider--on-the-road .slider__item__background img {
  max-height: 130px;
}

@media only screen and (max-width: 1023px) {
  .slider--on-the-road .slider__item__background img {
    max-height: 100px;
  }
}
.slider--on-the-road .slider__item__content {
  background: #f8f8f8;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
  margin: 40px 20px;
  min-height: 350px;
  padding: 50px 50px 80px;
  z-index: 1;
}

@media only screen and (max-width: 1023px) {
  .slider--on-the-road .slider__item__content {
    margin: 40px 10px;
    min-height: 300px;
    padding: 50px 20px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .slider--on-the-road .slider__item__content {
    min-height: 200px;
    padding: 30px 40px 20px;
  }
}
.slider--on-the-road .slider__item__desc,
.slider--on-the-road .slider__item__title {
  color: #7f818a;
  font-family: "Lato";
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.3;
  padding-bottom: 30px;
}

@media only screen and (max-width: 1023px) {
  .slider--on-the-road .slider__item__desc,
.slider--on-the-road .slider__item__title {
    font-size: 1.25rem;
    padding-bottom: 20px;
  }
}
.slider--on-the-road .slider__item__desc {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
}

@media only screen and (max-width: 1023px) {
  .slider--on-the-road .slider__item__desc {
    font-size: 0.875rem;
  }
}
/**
 * Timed-title component styles
 */
.timed-title {
  opacity: 0;
}

.timed-title.is-ready {
  opacity: 1;
  transition: opacity 0.3s;
}

/**
 * Booking-engine component styles
 */
.search-btn {
  background: #fff;
  border: 2px solid #f8aa00;
  bottom: 25px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  color: #f8aa00;
  font-size: 0;
  line-height: 2.875rem;
  min-height: 50px;
  min-width: 50px;
  position: fixed;
  right: 45px;
  text-align: center;
  transition: all 150ms linear;
  z-index: 599;
}

.search-btn:focus {
  z-index: 600;
}

@media only screen and (max-width: 767px) {
  .search-btn {
    bottom: 10px;
    right: 10px;
  }
}
.search-btn .icon-fonts {
  font-size: 1.5625rem;
  transition: all 300ms linear;
}

.booking-engine,
#ui-datepicker-div {
  display: none;
}

.msg_error_input {
  background: white;
  color: red;
  position: relative;
  top: 30px;
  z-index: 1;
}

/**
 * Fixed-logo component styles
 */
.fixed-logo {
  bottom: 25px;
  left: 50%;
  opacity: 0;
  position: fixed;
  text-align: center;
  transform: translate(-50%, 0);
  transition: all 150ms linear;
  z-index: 600;
}

.fixed-logo.bigOnInit {
  opacity: 1;
}

.fixed-logo.bigOnInit .brand-logo {
  width: 130px;
}

.fixed-logo.bigOnInit.visible .brand-logo {
  width: 100px;
}

@media only screen and (max-width: 767px) {
  .fixed-logo.bigOnInit.visible .brand-logo {
    width: 70px;
  }
}
.fixed-logo.visible {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .fixed-logo {
    bottom: 20px;
  }
}
.fixed-logo .brand-logo {
  display: block;
  height: auto;
  margin: 0 auto;
  transition: all 150ms linear;
  width: 100px;
}

@media only screen and (max-width: 767px) {
  .fixed-logo .brand-logo {
    width: 70px;
  }
}
.banner p {
  margin-top: 0;
}

.banner .section__content {
  margin: 0 auto;
  max-width: 1024px;
}

.banner .section__main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@media (min-width: 1025px) {
  .banner .section__main {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}
@media (min-width: 1025px) {
  .banner--right .section__main {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) {
  .banner--center {
    background-image: url("/frenchtrip/assets/images/banners/map.png");
    background-position: calc(50% - 320px) 30%;
    background-repeat: no-repeat;
    background-size: 500px;
  }
}
.banner__image-container {
  max-width: 400px;
  position: relative;
  width: 80%;
  z-index: 1;
}

@media (max-width: 1024px) {
  .banner__image-container {
    margin: 0 auto;
  }
}
@media (min-width: 1025px) {
  .banner__image-container {
    width: 35%;
  }
}
.banner__image-container:before {
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.banner__image {
  display: block;
}

.banner__legende {
  color: #fff;
  left: 50%;
  line-height: 1;
  position: absolute;
  text-align: center;
  text-shadow: 0 0 5px #000;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.banner__crossed {
  font-size: 1.75rem;
  font-weight: 900;
  left: 50%;
  opacity: 0;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.banner__crossed:after {
  background-color: #fff;
  content: "";
  height: 10%;
  left: 0;
  position: absolute;
  top: 58%;
  transform: translateY(-50%);
  transition: width 0.3s ease-in-out;
  transition-delay: 0.8s;
  width: 0;
}

.banner--visible .banner__crossed {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: peekaboo;
}

.banner--visible .banner__crossed:after {
  width: 100%;
}

.banner__real {
  font-family: "Megafresh";
  font-size: 2.75rem;
  opacity: 0;
  transition-delay: 2s;
  transition-duration: 0.3s;
  transition-property: opacity, visibility;
  transition-timing-function: ease;
  visibility: hidden;
}

.banner--visible .banner__real {
  opacity: 1;
  visibility: visible;
}

.banner__text {
  padding: 200px 20px 20px;
  position: relative;
}

.banner--left .banner__text {
  color: #000;
}

.banner--right .banner__text {
  color: #fff;
}

.banner--center .banner__text {
  margin: 0 auto;
  max-width: 500px;
  padding: 40px 20px;
  text-align: center;
}

@media (max-width: 1024px) {
  .banner__text {
    margin-top: -180px;
    text-align: center;
  }
}
@media (min-width: 1025px) {
  .banner__text {
    background-image: url("/frenchtrip/assets/images/banners/map.png");
    background-repeat: no-repeat;
    -ms-flex: 1;
    flex: 1;
  }

  .banner--left .banner__text {
    background-position: calc(100% + 155px) 50%;
    padding: 40px 200px 40px 50px;
  }

  .banner--right .banner__text {
    background-position: calc(0% - 155px) 50%;
    padding: 40px 50px 40px 200px;
  }

  .banner--center .banner__text {
    background-image: none;
  }
}
.banner__title {
  font-family: "Megafresh";
  font-size: 4rem;
  font-weight: 500;
  margin: 0 0 24px;
}

.banner__description {
  font-size: 1rem;
  margin-bottom: 32px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .banner__description {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }
}
.banner__cta {
  border: 2px solid;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 900;
  margin-bottom: 16px;
  padding: 8px 50px;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: 0.25s;
  transition-property: color, background-color, border-color;
  transition-timing-function: ease;
}

.festival-guide .banner__cta {
  border-color: #000;
}

.festival-guide .banner__cta:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.ride-style .banner__cta {
  border-color: #fff;
}

.ride-style .banner__cta:hover {
  background-color: #fff;
  border-color: #fff;
  color: #00747A;
}

.banner__badge {
  background-color: #fff;
  display: none;
  max-width: 100px;
  padding: 15px;
  padding-top: 50px;
  position: absolute;
  top: 0;
}

@media (min-width: 768px) {
  .banner__badge {
    display: block;
  }
}
.banner--left .banner__badge {
  right: calc(50% - 320px);
}

@media (min-width: 1025px) {
  .banner--left .banner__badge {
    right: 30px;
  }
}
@media (min-width: 1025px) {
  .banner--right .banner__badge {
    left: 30px;
  }
}
@media (min-width: 768px) {
  .banner--center .banner__badge {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    left: calc(100% + 20px);
    padding-top: 0;
    width: 100px;
  }
}
@media (min-width: 1025px) {
  .banner--center .banner__badge {
    left: calc(100% + 40px);
  }
}
@keyframes peekaboo {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  33% {
    opacity: 1;
    visibility: visible;
  }
  66% {
    opacity: 1;
    transform: translate(-50%, -50%);
    visibility: visible;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -160%) scale(0.6);
    visibility: visible;
  }
}
/**
 * Variables partial styles
 */
/* -- BREAKPOINT -- */
.adunit[data-adunit=overTopHeader].is-done {
  -ms-flex-align: center;
  align-items: center;
  background-color: #f2d166;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px;
  height: 4em;
  left: 0;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px;
  position: relative;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.adunit[data-adunit=overTopHeader].display-block > *:not(.emergencyClose) {
  height: 100% !important;
  padding: 0 10px;
  width: 100% !important;
}

.adunit[data-adunit=overTopHeader].display-block iframe {
  display: block;
  height: 100%;
  margin: auto;
  max-width: 100%;
  width: 100%;
}

.adunit[data-adunit=overTopHeader].display-block .emergencyClose {
  background: transparent;
  border: 0;
  text-align: center;
}

.adunit[data-adunit=overTopHeader].display-block .emergencyClose:before {
  color: #252339;
  content: "✖";
  display: table-cell;
  font-size: 1.5em;
  text-align: center;
  vertical-align: middle;
}

.adunit[data-adunit=overTopHeader].display-block .emergencyClose:hover {
  cursor: pointer;
}

/**
OAS TO DFP - ENCART EMERGENCY
*/
body.has-coverPage .privateDealContainer {
  display: none;
}

.adunit {
  position: relative;
  z-index: 1;
}

.adunit.display-none {
  display: none;
}

.adunit[data-adunit=multiBoard] {
  padding-bottom: 15px;
  padding-top: 15px;
}

.adunit[data-adunit=coverZone] {
  display: none;
}

.adunit[data-adunit=coverZone].display-block {
  display: block;
}

.adunit[data-adunit=coverZone].display-block + .slider.sliderPrez {
  display: none;
}

div#promotions {
  text-align: center;
}

/* Slider */
.slick-loading .slick-list {
  background: none;
}

#multiBoard-section .adunit[data-adunit=multiBoard].is-done {
  margin-top: 96px;
  text-align: center;
}

.section__popin-content {
  background: #fff;
  color: #574b4a;
  display: none;
  font-size: 0.875rem;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20000;
}

.section__popin-content .icon-fonts-close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 20;
}

.section__popin-content--wrapper {
  height: 100%;
  overflow: auto;
  padding: 50px 20px 20px;
  position: absolute;
  width: 100%;
}

.section--book-your-hotel {
  margin-bottom: 100px;
  position: relative;
  z-index: 600;
}

@media only screen and (max-width: 1023px) {
  .section--book-your-hotel {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 599px) {
  .section--book-your-hotel {
    margin-bottom: 50px;
  }
}
.erm__notScrollPage .section--book-your-hotel {
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .section--book-your-hotel {
    min-height: 400px;
  }
}
.section--book-your-hotel .brand-logo {
  display: block;
  height: 71px;
  left: 50%;
  margin-left: calc(-174px / 2);
  position: absolute;
  top: -90px;
  width: 174px;
}

.section--book-your-hotel .section__background {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.section--book-your-hotel .section__background picture {
  display: block;
  height: 100%;
  width: 100%;
}

.section--book-your-hotel .section__background--with-yellow-filter:after {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 154, 0, 0.3) 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.section--book-your-hotel .section__content {
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  z-index: 2;
}

.section--book-your-hotel .section__header {
  text-align: center;
}

.section--book-your-hotel .section__main {
  display: block;
  font-size: 0;
  margin: 0 auto;
  text-align: center;
  transition: all 150ms linear;
}

.section--book-your-hotel .section__main:focus {
  border: 4px solid #000;
}

.section--book-your-hotel .section__main.hidden-on-load {
  opacity: 0;
  pointer-events: none;
}

.section--book-your-hotel .section__main .fake-input {
  background: #fff;
  color: #130c0e;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 3.125rem;
  min-height: 50px;
  padding: 0 12px;
  text-align: left;
  text-decoration: none;
  transition: all 150ms linear;
  vertical-align: middle;
  width: 530px;
}

.section--book-your-hotel .section__main .fake-input .icon-fonts {
  color: #000;
  font-size: 1.5rem;
  margin-right: 9px;
  transition: all 150ms linear;
}

@media only screen and (max-width: 767px) {
  .section--book-your-hotel .section__main .fake-input {
    display: inline-block;
    padding-right: 20px;
    width: auto;
  }
}
.section--book-your-hotel .section__main .fake-cta {
  background: #f8aa00;
  color: #000;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 3.125rem;
  margin-left: 5px;
  min-height: 50px;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  .section--book-your-hotel .section__main .fake-cta {
    display: none;
  }
}
.section--book-your-hotel .section__title {
  color: #fff;
  font-family: "Megafresh";
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 9.6875rem;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 1023px) {
  .section--book-your-hotel .section__title {
    line-height: 1;
  }
}
@media only screen and (max-width: 767px) {
  .section--book-your-hotel .section__title {
    font-size: 3.75rem;
  }
}
.section--everywhere {
  padding-bottom: 200px;
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .section--everywhere {
    padding-bottom: 50px;
  }
}
.section--everywhere .section__background {
  left: 50%;
  opacity: 0.6;
  position: absolute;
  text-align: right;
  transform: translate(-50%, 0);
  width: 1280px;
}

@media only screen and (max-width: 1023px) {
  .section--everywhere .section__background {
    top: 30px;
    width: 100%;
  }
}
@media only screen and (max-width: 599px) {
  .section--everywhere .section__background {
    display: none;
  }
}
.section--everywhere .section__title {
  color: #f8aa00;
}

.section--everywhere .section__popin-content--picto {
  padding: 0 0 20px;
  text-align: center;
}

.section--everywhere .section__popin-content--picto img {
  display: inline-block;
  max-width: 50%;
}

.section--everywhere .section__popin-content--title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1;
  padding-bottom: 20px;
}

.section--everywhere .section__popin-content--desc {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-bottom: 20px;
}

.section--everywhere .section__popin-content--list-text {
  display: block;
  padding-left: 20px;
  position: relative;
}

.section--everywhere .section__popin-content--list-text:before {
  background: #f8aa00;
  border-radius: 99px;
  content: "";
  height: 10px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 10px;
}

.section--everywhere .section__popin-content--list .list__item {
  margin-bottom: 20px;
}

.section--everywhere .section__popin-content--list .icon-fonts {
  background: #f8aa00;
  border-radius: 99px;
  color: #fff;
  height: 28px;
  line-height: 1.75rem;
  margin-right: 10px;
  overflow: hidden;
  text-align: center;
  width: 28px;
}

.section--everywhere .section__main {
  font-size: 0;
  margin: 0 auto;
  max-width: 960px;
  position: relative;
}

.section--everywhere .section__infos {
  color: #130c0e;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.8;
  padding-left: 6%;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

@media only screen and (max-width: 1023px) {
  .section--everywhere .section__infos {
    padding-left: 6%;
  }
}
@media only screen and (max-width: 767px) {
  .section--everywhere .section__infos {
    display: none;
  }
}
.section--everywhere .section__mobile-cta {
  display: none;
}

@media only screen and (max-width: 767px) {
  .section--everywhere .section__mobile-cta {
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 10;
  }

  .section--everywhere .section__mobile-cta .button {
    border: 2px solid #fff;
    bottom: 20px;
    color: #fff;
    font-size: 0.9375rem;
    left: 50%;
    line-height: 2.875rem;
    margin: 0 auto;
    min-width: 220px;
    padding: 0 10px;
    position: absolute;
    text-decoration: none;
    transform: translate(-50%, 0);
  }
}
.section--everywhere .section__visual {
  display: inline-block;
  left: 110px;
  position: relative;
  vertical-align: middle;
  width: 40%;
}

@media only screen and (max-width: 1023px) {
  .section--everywhere .section__visual {
    left: 0;
    padding-left: 6%;
  }
}
@media only screen and (max-width: 767px) {
  .section--everywhere .section__visual {
    display: block;
    margin: 0 auto;
    max-width: 90%;
    padding-left: 0;
    width: 353px;
  }
}
.section--everywhere .section__visual--main {
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
  display: block;
  max-width: 100%;
  position: relative;
  z-index: 1;
}

.section--everywhere .section__visual--picto {
  bottom: -40px;
  display: block;
  left: -157px;
  position: absolute;
  z-index: 2;
}

@media only screen and (max-width: 1023px) {
  .section--everywhere .section__visual--picto {
    left: -77px;
  }
}
@media only screen and (max-width: 767px) {
  .section--everywhere .section__visual--picto {
    display: none;
  }
}
.section.banner {
  padding-top: 40px;
}

.section--best-offers {
  padding-top: 40px;
}

@media only screen and (max-width: 767px) {
  .section--best-offers {
    padding-top: 50px;
  }
}
.section--best-offers .section__background {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.section--best-offers .section__content {
  position: relative;
}

.section--best-offers .section__header {
  display: block;
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
}

.section--best-offers .section__nav {
  font-size: 0;
  margin-top: 0;
  position: absolute;
  right: 20px;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .section--best-offers .section__nav {
    display: none;
  }
}
.section--best-offers .section__title {
  color: #24abab;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}

.section--inspirations {
  padding-top: 155px;
}

@media only screen and (max-width: 767px) {
  .section--inspirations {
    padding-top: 55px;
  }
}
.section--inspirations .section__baseline {
  color: #130c0e;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 80%;
}

@media only screen and (max-width: 767px) {
  .section--inspirations .section__baseline {
    display: none;
  }
}
.section--inspirations .section__content {
  margin: 0 auto;
  max-width: 1024px;
  position: relative;
}

.section--inspirations .section__header {
  left: 0;
  padding-left: 20px;
  position: absolute;
  top: 0;
  width: 422px;
  z-index: 20;
}

@media only screen and (max-width: 1023px) {
  .section--inspirations .section__header {
    width: 382px;
  }
}
@media only screen and (max-width: 767px) {
  .section--inspirations .section__header {
    padding: 0 20px;
    position: relative;
    width: 100%;
  }
}
.section--inspirations .section__nav {
  font-size: 0;
  margin-top: 35px;
}

@media only screen and (max-width: 767px) {
  .section--inspirations .section__nav {
    display: none;
  }
}
.section--inspirations .section__title {
  color: #574b4a;
  font-family: "Manifoldcf";
  font-weight: 300;
  margin: 20px 0;
  max-width: 80%;
  padding: 0;
  text-align: left;
  word-break: break-word;
}

@media only screen and (max-width: 767px) {
  .section--inspirations .section__title {
    max-width: 100%;
    text-align: center;
  }
}
.section--itineraries {
  padding-top: 60px;
}

@media only screen and (max-width: 767px) {
  .section--itineraries {
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .section--itineraries .section__baseline {
    display: none;
  }
}
.section--itineraries .section__baseline__text {
  color: #130c0e;
  font-size: 1rem;
  line-height: 1.5;
}

.section--itineraries .section__content {
  margin: 0 auto;
  max-width: 1400px;
  position: relative;
}

.section--itineraries .section__cta {
  font-size: 0;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .section--itineraries .section__cta {
    display: none;
  }
}
.section--itineraries .section__cta .button {
  border-color: #e92d8e;
  color: #e92d8e;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 2.75rem;
  margin-top: 35px;
  min-height: 47px;
  min-width: 165px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  transition: all 150ms linear;
}

.section--itineraries .section__cta .button:hover {
  background: #e92d8e;
  color: #fff;
}

.section--itineraries .section__header {
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
  text-align: center;
}

.section--itineraries .section__nav {
  bottom: 0;
  font-size: 0;
  margin-top: 35px;
  position: absolute;
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .section--itineraries .section__nav {
    display: none;
  }
}
.section--itineraries .section__title {
  color: #e92d8e;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .section--itineraries .section__title {
    margin: 0;
    max-width: 100%;
  }
}
.section--itineraries a.slider__item:focus {
  outline: 3px solid #e92d8e;
}

.section--our-services {
  padding: 50px 0 96px;
}

@media only screen and (max-width: 1023px) {
  .section--our-services {
    padding-bottom: 66px;
  }
}
.section--our-services .section__content {
  margin: 0 auto;
  max-width: 1024px;
  text-align: center;
}

.section--our-services .section__header {
  display: block;
}

.section--our-services .section__title {
  color: #574b4a;
}

.section--our-services .section__main .list {
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .section--our-services .section__main .list {
    margin-bottom: -70px;
    padding-top: 70px;
  }
}
.section--our-services .section__main .list__animation {
  display: inline-block;
  min-height: 230px;
  position: relative;
}

.section--our-services .section__main .list__item {
  display: inline-block;
  font-size: 1.25rem;
  min-width: 256px;
  position: relative;
  width: 25%;
}

@media only screen and (max-width: 1023px) {
  .section--our-services .section__main .list__item {
    margin-bottom: 30px;
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .section--our-services .section__main .list__item {
    margin-top: -100px;
    min-width: 100px;
    transform: scale(0.65);
    vertical-align: top;
  }
}
.section--our-services .section__main .list__item--welcome .list__big-title {
  left: 0;
  top: 0;
  width: 100%;
}

.section--our-services .section__main .list__item--welcome .needle-long {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: animneedle;
  animation-timing-function: linear;
  left: 18px;
  position: absolute;
  top: 126px;
  transform-origin: 100% 100%;
  width: 31px;
}

.section--our-services .section__main .list__item--welcome .needle-short {
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-name: animneedle;
  animation-timing-function: linear;
  left: 49px;
  position: absolute;
  top: 137px;
  transform-origin: 0 0;
  width: 16px;
}

.section--our-services .section__main .list__item--welcome .watch {
  display: block;
  height: auto;
  margin-top: 32px;
  max-width: 300px;
  width: 98px;
}

@keyframes animneedle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.section--our-services .section__main .list__item--wifi .list__big-title {
  left: 50%;
  padding-right: 22px;
  text-align: right;
  top: 160px;
  transform: translate(-50%, 0);
  width: 200px;
}

@media only screen and (max-width: 767px) {
  .section--our-services .section__main .list__item--wifi .list__big-title {
    margin-left: -12px;
    text-align: left;
  }
}
.section--our-services .section__main .list__item--wifi .mobile {
  display: block;
  height: auto;
  margin-top: 65px;
  max-width: 300px;
  width: 80px;
}

.section--our-services .section__main .list__item--wifi .wave-1 {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: animonde1;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  height: auto;
  left: 3px;
  position: absolute;
  top: 48px;
  width: 23px;
}

.section--our-services .section__main .list__item--wifi .wave-2 {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: animonde2;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  height: auto;
  left: -11px;
  position: absolute;
  top: 34px;
  width: 37px;
}

.section--our-services .section__main .list__item--wifi .wave-3 {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: animonde3;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  height: auto;
  left: -24px;
  position: absolute;
  top: 19px;
  width: 51px;
}

@keyframes animonde1 {
  0% {
    opacity: 0;
    transform: scale(0.9) translate(10%, 10%);
  }
  33% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes animonde2 {
  0% {
    opacity: 0;
    transform: scale(0.9) translate(10%, 10%);
  }
  13% {
    opacity: 0;
    transform: scale(0.9) translate(10%, 10%);
  }
  43% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes animonde3 {
  0% {
    opacity: 0;
    transform: scale(0.9) translate(10%, 10%);
  }
  23% {
    opacity: 0;
    transform: scale(0.9) translate(10%, 10%);
  }
  53% {
    opacity: 1;
    transform: scale(1);
  }
}
.section--our-services .section__main .list__item--parking .list__big-title {
  left: 0;
  top: 0;
  width: 100%;
}

.section--our-services .section__main .list__item--parking .van {
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-name: animvan;
  animation-timing-function: linear;
  display: block;
  height: auto;
  margin-top: 90px;
  max-width: 300px;
  transform-origin: 50% 100%;
  width: 180px;
}

.section--our-services .section__main .list__item--parking .wheel-1,
.section--our-services .section__main .list__item--parking .wheel-2 {
  height: auto;
  position: absolute;
  transform-origin: 50% 50%;
  width: 31px;
}

.section--our-services .section__main .list__item--parking .wheel-1 {
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-name: animwheel;
  animation-timing-function: linear;
  left: 19px;
  top: 151px;
}

.section--our-services .section__main .list__item--parking .wheel-2 {
  animation-delay: 0.75s;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-name: animwheel;
  animation-timing-function: linear;
  left: 124px;
  top: 151px;
}

.section--our-services .section__main .list__item--parking .surf-1 {
  animation-duration: 0.2s;
  animation-iteration-count: infinite;
  animation-name: animsurf;
  animation-timing-function: linear;
  height: auto;
  left: 20px;
  position: absolute;
  top: 77px;
  width: 125px;
}

.section--our-services .section__main .list__item--parking .surf-2 {
  animation-delay: 0.5s;
  animation-duration: 0.25s;
  animation-iteration-count: infinite;
  animation-name: animsurf;
  animation-timing-function: linear;
  height: auto;
  left: 42px;
  position: absolute;
  top: 70px;
  width: 87px;
}

@keyframes animvan {
  0% {
    transform: rotate(1deg);
  }
  15% {
    transform: rotate(-1deg);
  }
  30% {
    transform: rotate(0deg);
  }
  45% {
    transform: rotate(-1deg);
  }
  65% {
    transform: rotate(0.5deg);
  }
}
@keyframes animwheel {
  0% {
    transform: rotate(0deg) translate(0, -5%);
  }
  50% {
    transform: rotate(180deg) translate(0, 0%);
  }
  100% {
    transform: rotate(360deg) translate(0, 5%);
  }
}
@keyframes animsurf {
  0% {
    transform: rotate(0deg) translate(0, -5%);
  }
  50% {
    transform: rotate(1deg) translate(0, 0%);
  }
  100% {
    transform: rotate(-1deg) translate(0, 5%);
  }
}
.section--our-services .section__main .list__item--breakfast .list__big-title {
  left: 0;
  top: 156px;
  width: 100%;
}

.section--our-services .section__main .list__item--breakfast .coffee-maker {
  display: block;
  height: auto;
  margin-top: 69px;
  max-width: 300px;
  width: 115px;
}

.section--our-services .section__main .list__item--breakfast .steam-1,
.section--our-services .section__main .list__item--breakfast .steam-2,
.section--our-services .section__main .list__item--breakfast .steam-3 {
  position: absolute;
  transform-origin: 50% 100%;
}

.section--our-services .section__main .list__item--breakfast .steam-1 {
  animation-delay: 0s;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: animmoke1;
  animation-timing-function: linear;
  height: 28px;
  left: 38px;
  top: 39px;
  width: auto;
}

.section--our-services .section__main .list__item--breakfast .steam-2 {
  animation-delay: 0.2s;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: animmoke2;
  animation-timing-function: linear;
  height: 39px;
  left: 46px;
  top: 29px;
  width: auto;
}

.section--our-services .section__main .list__item--breakfast .steam-3 {
  animation-delay: 0.4s;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: animmoke3;
  animation-timing-function: linear;
  height: 30px;
  left: 54px;
  top: 41px;
  width: auto;
}

@keyframes animmoke1 {
  0% {
    height: 28px;
    opacity: 0;
    width: 6.5px;
  }
  60% {
    height: calc(28px * 2);
    opacity: 1;
    transform: translate(0, -60%);
    width: 6.5px;
  }
  99% {
    height: calc(28px * 3);
    opacity: 0;
    transform: translate(0, -100%);
    width: 6.5px;
  }
  100% {
    height: 28px;
    opacity: 0;
    width: 6.5px;
  }
}
@keyframes animmoke2 {
  0% {
    height: 39px;
    opacity: 0;
    width: 9.6px;
  }
  60% {
    height: calc(28px * 2);
    opacity: 1;
    transform: translate(0, -60%);
    width: 6.5px;
  }
  99% {
    height: calc(28px * 3.5);
    opacity: 0;
    transform: translate(0, -100%);
    width: 9.6px;
  }
  100% {
    height: 28px;
    opacity: 0;
    width: 9.6px;
  }
}
@keyframes animmoke3 {
  0% {
    height: 30px;
    opacity: 0;
    width: 9.4px;
  }
  60% {
    height: calc(28px * 2);
    opacity: 1;
    transform: translate(0, -60%);
    width: 9.4px;
  }
  99% {
    height: calc(28px * 3);
    opacity: 0;
    transform: translate(0, -100%);
    width: 9.4px;
  }
  100% {
    height: 28px;
    opacity: 0;
    width: 9.4px;
  }
}
.section--our-services .section__main .list__big-title {
  color: #574b4a;
  display: block;
  font-family: "Megafresh";
  font-size: 3.125rem;
  font-weight: 400;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  z-index: 10;
}

.section--our-services .section__main .list__infos {
  color: #130c0e;
  font-size: 0.875rem;
  line-height: 1.8;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .section--our-services .section__main .list__infos {
    font-size: 1.125rem;
    line-height: 1.5;
  }
}
.section--our-services .section__main .list__infos strong {
  font-size: 1rem;
}

@media only screen and (max-width: 767px) {
  .section--our-services .section__main .list__infos strong {
    font-size: 1.25rem;
  }
}
.section--road-trip-spirit {
  position: relative;
  z-index: 598;
}

@media only screen and (max-width: 1023px) {
  .section--road-trip-spirit {
    background: #f8aa00;
  }
}
.section--road-trip-spirit .section__background {
  left: 50%;
  position: absolute;
  text-align: right;
  transform: translate(-50%, 0);
  width: 1280px;
}

@media only screen and (max-width: 1023px) {
  .section--road-trip-spirit .section__background {
    top: 30px;
    width: 100%;
  }
}
@media only screen and (max-width: 599px) {
  .section--road-trip-spirit .section__background {
    display: none;
  }
}
.section--road-trip-spirit .section__content {
  margin: 0 auto;
  max-width: 1024px;
  padding: 90px 20px 125px;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 1023px) {
  .section--road-trip-spirit .section__content {
    padding: 30px 0 80px;
  }
}
@media only screen and (max-width: 599px) {
  .section--road-trip-spirit .section__content {
    height: 330px;
    margin-bottom: 60px;
    padding-bottom: 0;
  }
}
.section--road-trip-spirit .section__header {
  text-align: center;
  width: 60%;
}

@media only screen and (max-width: 1023px) {
  .section--road-trip-spirit .section__header {
    width: 100%;
  }
}
.section--road-trip-spirit .section__main .editorial {
  background: #f8aa00;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  color: #130c0e;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 40px 200px 40px 67px;
  width: 72%;
}

.section--road-trip-spirit .section__main .editorial p {
  margin-top: 0;
}

@media only screen and (max-width: 1023px) {
  .section--road-trip-spirit .section__main .editorial {
    display: none;
  }
}
.section--road-trip-spirit .section__title {
  color: #574b4a;
  font-family: "Megafresh";
  font-size: 5.625rem;
  font-weight: 400;
  padding-bottom: 30px;
}

@media only screen and (max-width: 599px) {
  .section--road-trip-spirit .section__title {
    font-size: 3rem;
  }
}
.section--where-do-you-go {
  margin: 0 auto;
  max-width: 1024px;
  padding-top: 50px;
  position: relative;
}

.section--where-do-you-go:after {
  bottom: 0;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
  content: "";
  height: 40px;
  left: 50%;
  margin-left: -450px;
  position: absolute;
  width: 900px;
}

.section--where-do-you-go .section__content {
  background: #fff;
  position: relative;
  z-index: 10;
}

.section--where-do-you-go .section__footer {
  background: #fff;
  clear: both;
  padding: 40px 0 20px;
  position: relative;
}

.section--where-do-you-go .section__footer .list {
  font-size: 0;
  text-align: center;
}

.section--where-do-you-go .section__footer .list__item {
  color: #130c0e;
  font-size: 0.875rem;
  position: relative;
  text-align: center;
  width: 50%;
}

@media only screen and (max-width: 599px) {
  .section--where-do-you-go .section__footer .list__item {
    padding: 0 10px;
  }

  .section--where-do-you-go .section__footer .list__item:first-child:after {
    background: #e5e6e8;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
  }

  .section--where-do-you-go .section__footer .list__item:nth-child(2) {
    padding-left: 15px;
  }
}
.section--where-do-you-go .section__footer .list__item .icon-fonts {
  color: #f8aa00;
  font-size: 2.75rem;
  margin-right: 20px;
}

@media only screen and (max-width: 599px) {
  .section--where-do-you-go .section__footer .list__item .icon-fonts {
    margin-right: 10px;
  }
}
.section--where-do-you-go .section__footer .list__label {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: calc(100% - 74px);
}

@media only screen and (max-width: 599px) {
  .section--where-do-you-go .section__footer .list__label {
    width: calc(100% - 64px);
  }
}
.embedded-video__poster {
  top: 20px;
}