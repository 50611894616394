@import '../../../../common.accor.com/dfp/styles';

/* Slider */
.slick-loading .slick-list {
  background: none;
}

#multiBoard-section {
  & .adunit[data-adunit='multiBoard'] {
    &.is-done {
      margin-top: 96px;
      text-align: center;
    }
  }
}
