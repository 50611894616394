
/**
 * Slider component styles
 */
@import 'npm://slick-carousel/slick/slick';

.slider {
  &__item {
    display: block;
    text-decoration: none;

    &__background,
    &__badge,
    &__content,
    &__cta,
    &__inner {
      display: block;
    }
  }

  .slick-dots {
    bottom: -28px;
    display: block;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    li {
      cursor: pointer;
      display: inline-block;
      height: 11px;
      margin: 0 5px;
      padding: 0;
      position: relative;
      width: 11px;

      button {
        background: transparent;
        border: 0;
        color: transparent;
        cursor: pointer;
        display: block;
        font-size: 0;
        height: 11px;
        line-height: 0;
        padding: 5px;
        width: 11px;

        &:before {
          background: #dbdcdf;
          border-radius: 100%;
          content: '';
          height: 11px;
          left: 0;
          line-height: 20 * @rem;
          position: absolute;
          text-align: center;
          top: 0;
          width: 11px;
        }
      }
    }
  }

  &--best-offers {
    .foo {
      font-size: 20 * @rem;
      font-weight: 700;
      text-align: center;
    }

    .slick-dots {
      bottom: 0;

      li {
        button {
          &:before {
            background: @turquoise;
            border-radius: 99px;
            transition: all 150ms linear;
          }
        }

        &.slick-active {
          width: 22px;

          button {
            width: 22px;

            &:before {
              width: 22px;
            }
          }
        }
      }
    }

    .slick-list {
      @media @smartphone {
        overflow: visible;
      }
    }

    .slider {
      &__button {
        border: 2px solid @turquoise;
        color: @turquoise;
        display: inline-block;
        font-size: 0;
        line-height: 43 * @rem;
        min-height: 47px;
        text-align: center;
        text-decoration: none;
        transition: all 150ms linear;
        width: 47px;

        &:hover {
          background: @turquoise;
          color: @white;
        }

        &--next {
          margin-left: -2px;

          &:before {
            content: '→';
            font-size: 17 * @rem;
          }
        }

        &--prev {
          &:before {
            content: '←';
            font-size: 17 * @rem;
          }
        }
      }

      &__infos {
        @media @smartphone {
          background: @white;
          box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
          display: block;
          padding: 20px;
        }
      }

      &__item {
        min-width: 714px;
        padding: 35px 34px 50px;
        position: relative;

        @media @smartphone {
          max-width: 256px;
          min-width: 200px;
          padding: 0 5px;
        }

        &__background {
          box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
          position: relative;
          z-index: 1;

          &:after {
            background: rgba(0, 0, 0, 0.35);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }

        &__baseline {
          display: block;
          font-size: 16 * @rem;
          font-weight: 700;
          margin: 0 auto;
          max-width: 600px;
          padding: 40px 20px 0;

          @media @smartphone {
            color: @darktext;
            min-height: 70px;
            padding: 0;
          }
        }

        &__content {
          color: @white;
          left: 0;
          padding: 50px 34px;
          position: absolute;
          text-align: center;
          top: 50%;
          transform: translate(0, -50%);
          width: 100%;
          z-index: 2;

          @media @smartphone {
            left: 0;
            padding: 0;
            position: relative;
            top: 0;
            transform: translate(0, 0);
          }
        }

        &__cta {
          .button {
            border: 0;
            border-color: @white;
            display: inline-block;
            font-family: 'Lato';
            font-size: 14 * @rem;
            line-height: 43 * @rem;
            margin-top: 40px;
            min-height: 47px;
            min-width: 180px;
            padding: 0 20px;
            position: relative;
            text-align: center;
            text-decoration: none;

            @media @smartphone {
              border-color: @lightgrey;
              color: @lightgrey;
              margin-top: 0;
            }

            &:after {
              border: 2px solid @white;
              content: '';
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              transition: all 400ms cubic-bezier(0.600, -0.280, 0.735, 0.045);
              width: 100%;

              @media @smartphone {
                border-color: @lightgrey;
              }
            }

            &:hover {
              &:after {
                transform: scale(1.1);
              }
            }
          }
        }

        &__title {
          display: block;
          font-family: 'Megafresh';
          font-size: 50 * @rem;
          font-weight: 400;
          line-height: 1;

          @media @smartphone {
            font-size: 40 * @rem;
            height: 159px;
            margin: -159px 0 0;
            padding: 0 5px;
            position: relative;

            span {
              display: block;
              position: relative;
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }
      }
    }
  }

  &--exclus {
    .slick-dots {
      bottom: 0;

      li {
        button {
          &:before {
            background: @pink;
            border-radius: 99px;
            transition: all 150ms linear;
          }
        }

        &.slick-active {
          width: 22px;

          button {
            width: 22px;

            &:before {
              width: 22px;
            }
          }
        }
      }
    }

    .slick-list {
      @media @smartphone {
        overflow: visible;
      }
    }

    .slider {
      &__button {
        border: 2px solid @pink;
        color: @pink;
        display: inline-block;
        font-size: 0;
        line-height: 43 * @rem;
        margin: 0 10px;
        min-height: 47px;
        text-align: center;
        text-decoration: none;
        transition: all 150ms linear;
        width: 47px;

        &:hover {
          background: @pink;
          color: @white;
        }

        &--next {
          margin-left: -2px;

          &:before {
            content: '→';
            font-size: 17 * @rem;
          }
        }

        &--prev {
          &:before {
            content: '←';
            font-size: 17 * @rem;
          }
        }
      }

      &__infos {
        @media @smartphone {
          background: @white;
          box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
          display: block;
          padding: 20px;
        }
      }

      &__item {
        min-width: 714px;
        padding: 35px 34px 50px;
        position: relative;

        @media @smartphone {
          max-width: 256px;
          min-width: 200px;
          padding: 0 5px;
        }

        &__background {
          box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
          position: relative;
          z-index: 1;
        }

        &__baseline {
          display: block;
          font-size: 16 * @rem;
          font-weight: 700;
          margin: 0 auto;
          max-width: 600px;
          padding: 40px 20px 0;

          @media @smartphone {
            color: @darktext;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.8;
            min-height: 70px;
            padding: 0;
            text-align: justify;
          }
        }

        &__content {
          color: @white;
          left: 0;
          padding: 50px 34px;
          position: absolute;
          text-align: center;
          top: 50%;
          transform: translate(0, -50%);
          width: 100%;
          z-index: 2;

          @media @smartphone {
            left: 0;
            padding: 0;
            position: relative;
            top: 0;
            transform: translate(0, 0);
          }
        }

        &__cta {
          .button {
            border: 0;
            border-color: @white;
            display: inline-block;
            font-family: 'Lato';
            font-size: 14 * @rem;
            line-height: 43 * @rem;
            margin-top: 40px;
            min-height: 47px;
            min-width: 180px;
            padding: 0 20px;
            position: relative;
            text-align: center;
            text-decoration: none;

            @media @smartphone {
              border-color: @lightgrey;
              color: @lightgrey;
              margin-top: 0;
            }

            &:after {
              border: 2px solid @white;
              content: '';
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              transition: all 400ms cubic-bezier(0.600, -0.280, 0.735, 0.045);
              width: 100%;

              @media @smartphone {
                border-color: @lightgrey;
              }
            }

            &:hover {
              &:after {
                transform: scale(1.1);
              }
            }
          }
        }

        &__title {
          display: block;
          font-family: 'Megafresh';
          font-size: 100 * @rem;
          font-weight: 400;
          line-height: 1;

          @media @smartphone {
            font-size: 40 * @rem;
            height: 159px;
            margin: -159px 0 0;
            padding: 0 5px;
            position: relative;

            span {
              display: block;
              position: relative;
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }
      }
    }
  }

  &--inspirations {
    .foo {
      display: none;
    }

    &[data-role='slider-a11y'] {
      .slick-track {
        left: -280px;

        @media @tablet {
          left: -230px;
        }

        @media @mobile {
          left: 0;
        }
      }
    }

    .slider {
      &__button {
        border: 2px solid @greybrown;
        color: @greybrown;
        display: inline-block;
        font-size: 0;
        line-height: 41 * @rem;
        margin-right: 20px;
        min-height: 45px;
        text-align: center;
        text-decoration: none;
        transition: all 150ms linear;
        width: 45px;

        &:hover {
          background: @greybrown;
          color: @white;
        }

        &--next {
          margin-left: -2px;

          &:before {
            content: '→';
            font-size: 17 * @rem;
          }
        }

        &--prev {
          &:before {
            content: '←';
            font-size: 17 * @rem;
          }
        }
      }

      &__content--main {
        left: 422px;
        min-width: 847px;
        position: relative;
        z-index: 100;

        @media @tablet {
          left: 382px;
        }

        @media @smartphone {
          left: 0;
          min-width: 300px;
          padding: 0 10px 60px;

          .slick-list {
            overflow: visible;
          }
        }

        .slider {
          &__item {
            display: block;
            min-width: 467px;
            opacity: 0.3;
            padding-bottom: 50px;
            position: relative;
            transition: all 300ms linear;

            @media @tablet {
              min-width: 390px;
            }

            @media @smartphone {
              max-width: 143px;
              min-width: 143px;
              opacity: 1;
              padding: 0 5px;
            }

            &.slick-current {
              opacity: 1;
            }

            &__background {
              height: 100%;
              left: 0;
              overflow: hidden;
              position: absolute;
              top: 0;
              width: 100%;
              z-index: 1;

              img {
                display: block;
                font-family: 'object-fit: cover';
                height: 100%;
                object-fit: cover;
                width: 100%;
              }
            }

            &__content {
              position: relative;
              top: 50%;
              transform: translate(0, -50%);
              z-index: 2;
            }

            &__cta {
              text-align: center;

              @media @smartphone {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
              }

              .button {
                border: 0;
                color: @white;
                display: inline-block;
                font-family: 'Lato';
                font-size: 14 * @rem;
                margin-top: 30px;
                min-width: 154px;
                padding: 12px 20px;
                position: relative;
                text-align: center;
                text-decoration: none;

                &:after {
                  border: 2px solid @white;
                  content: '';
                  height: 100%;
                  left: 0;
                  position: absolute;
                  top: 0;
                  transition: all 400ms cubic-bezier(0.600, -0.280, 0.735, 0.045);
                  width: 100%;
                }

                &:hover {
                  &:after {
                    transform: scale(1.1);
                  }
                }

                @media @smartphone {
                  color: transparent;
                  min-width: 100px;

                  &:after {
                    display: none;
                  }
                }
              }
            }

            &__inner {
              box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
              height: 380px;
              position: relative;
              width: 380px;

              @media @smartphone {
                height: 236px;
                width: 100%;
              }
            }

            &__title {
              color: @white;
              display: block;
              font-family: 'Megafresh';
              font-size: 60 * @rem;
              line-height: 1;
              text-align: center;

              @media @smartphone {
                font-size: 40 * @rem;
                padding: 0 10px;
              }
            }
          }
        }
      }

      &__content--synced {
        left: 222px;
        margin-top: -530px;
        min-width: 1247px;
        overflow: hidden;
        padding: 100px 0 100px 200px;
        position: relative;
        z-index: 10;

        @media @tablet {
          left: 182px;
        }

        @media @smartphone {
          display: none;
        }

        .slick-list {
          overflow: visible;
        }

        .slider {
          &__item {
            height: 380px;
            min-width: 467px;
            opacity: 0;
            position: relative;
            transition: all 300ms linear;

            @media @tablet {
              min-width: 390px;
            }

            &.slick-current {
              opacity: 1;
            }
          }

          &__visuel-left {
            left: -182px;
            position: absolute;
            top: -100px;
          }

          &__visuel-right {
            bottom: -100px;
            position: absolute;
            right: 30px;
          }
        }
      }
    }
  }

  &--itineraries {
    .slick-list {
      overflow: visible;
    }

    .foo {
      display: none;
    }

    .slider {
      &__button {
        border: 2px solid @pink;
        color: @pink;
        display: inline-block;
        font-size: 0;
        line-height: 43 * @rem;
        min-height: 47px;
        text-align: center;
        text-decoration: none;
        transition: all 150ms linear;
        width: 47px;

        &:hover {
          background: @pink;
          color: @white;
        }

        &--next {
          margin-left: -2px;

          &:before {
            content: '→';
            font-size: 17 * @rem;
          }
        }

        &--prev {
          &:before {
            content: '←';
            font-size: 17 * @rem;
          }
        }
      }

      &__content {
        margin-top: 110px;
        position: relative;
        z-index: 100;

        @media @smartphone {
          margin-top: 0;
          padding: 0 10px;
        }
      }

      &__item {
        margin: 0 25px;
        min-height: 200px;
        position: relative;
        width: 200px;

        @media @tablet {
          box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
          height: 230px;
          margin: 0 17px;
          min-width: 133px;
          width: 133px;
        }

        @media @smartphone {
          height: 260px;
          margin: 0 5px;
        }

        &:hover,
        &:focus {
          .slider__item__background {
            box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
            transform: translate(0, -75%);

            @media @tablet {
              box-shadow: none;
              transform: translate(0, 0);
            }
          }
        }

        &__background {
          box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
          margin-bottom: -200px;
          position: relative;
          transition: all 200ms linear;
          z-index: 2;

          @media @tablet {
            box-shadow: none;
            margin-bottom: 0;
          }

          img {
            display: block;
            height: auto;
            position: relative;
            width: 100%;
            z-index: 1;
          }
        }

        &__badge {
          border-radius: 3px;
          bottom: 20px;
          color: @white;
          display: block;
          font-family: 'Lato';
          font-size: 10 * @rem;
          left: 20px;
          padding: 5px;
          position: absolute;
          z-index: 2;

          &.festival-guide {
            color: @black;
          }

          @media @tablet {
            bottom: 10px;
            left: 10px;
          }
        }

        &__img-title {
          bottom: 0;
          color: @white;
          font-family: 'Manifoldcf';
          font-size: 36 * @rem;
          font-weight: 300;
          left: 0;
          line-height: 1;
          padding: 20px;
          position: absolute;
          width: 100%;
          z-index: 2;
        }

        &__baseline {
          color: @darktext;
          display: block;
          font-size: 14 * @rem;
          line-height: 1.5;
          overflow: hidden;
          padding: 0 20px;
          text-overflow: ellipsis;
          white-space: nowrap;

          &--with-wrap {
            white-space: normal;
          }

          @media @tablet {
            font-size: 12 * @rem;
            padding: 0 10px 30px;
          }
        }

        &__content {
          height: 100%;
          left: 0;
          padding-top: 50px;
          top: 0;
          width: 100%;
          z-index: 1;

          &--no-title {
            padding-top: 60px;
          }

          @media @tablet {
            height: auto;
            padding-top: 0;
            position: static;
          }
        }

        &__cta {
          padding-top: 18px;
          text-align: center;

          @media @tablet {
            height: 100%;
            left: 0;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 100;
          }

          .button {
            border: 2px solid @darktext;
            color: @darktext;
            display: inline-block;
            font-size: 14 * @rem;
            line-height: 1.3;
            max-width: 90%;
            min-width: 160px;
            padding: 5px 10px;
            text-decoration: none;
            transition: all 300ms linear;

            &:hover {
              background: @darktext;
              color: @white;
            }
          }
        }

        &__title {
          color: @darktext;
          display: block;
          font-size: 18 * @rem;
          font-weight: 700;
          line-height: 1.2;
          margin-top: 16px;
          overflow: hidden;
          padding: 0 20px 14px;

          @media @tablet {
            font-size: 16 * @rem;
            margin: 0;
            padding: 10px;
          }
        }
      }
    }
  }

  &--our-rooms {
    .slick-list {
      overflow: visible;
    }

    .slider {
      &__button {
        border: 2px solid @greybrown;
        color: @greybrown;
        display: inline-block;
        font-size: 0;
        line-height: 43 * @rem;
        margin: 0 10px;
        min-height: 47px;
        text-align: center;
        text-decoration: none;
        transition: all 150ms linear;
        width: 47px;

        &:hover {
          background: @greybrown;
          color: @white;
        }

        &--next {
          &:before {
            content: '→';
            font-size: 17 * @rem;
          }
        }

        &--prev {
          &:before {
            content: '←';
            font-size: 17 * @rem;
          }
        }
      }

      &__content {
        margin-top: 30px;
        position: relative;
        z-index: 100;

        @media @smartphone {
          margin-top: 0;
          padding: 0 10px;
        }
      }

      &__item {
        height: 464px;
        margin: 0 25px;
        position: relative;
        width: 464px;

        @media @smartphone {
          &:before {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 26%, rgba(252, 252, 252, 0) 27%, rgba(0, 0, 0, 0.3) 100%);
            bottom: 0;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }

        &__mobile-cta {
          display: none;

          @media @smartphone {
            bottom: 10px;
            display: block;
            left: 10px;
            position: absolute;
            text-align: center;
            width: calc(100% - 20px);

            .button {
              border: 2px solid @white;
              color: @white;
              display: block;
              font-size: 12 * @rem;
              line-height: 31 * @rem;
              text-align: center;
              width: 100%;
            }
          }
        }

        @media @smartphone {
          width: 133px;
        }

        &[data-slug='tandem'] {
          background: #dbceae;
          color: @greybrown;
        }

        &[data-slug='side-car'] {
          background: #dd2465;
          color: @white;
        }

        &[data-slug='break'] {
          background: #f59c19;
          color: @white;
        }

        &[data-slug='cabrio'] {
          background: #00747a;
          color: @white;
        }

        &[data-slug='combi'] {
          background: #2db5b4;
          color: @white;
        }

        @media @smartphone {
          height: 260px;
          margin: 0 5px;
        }

        &__room {
          color: @black;
          display: block;
          font-family: 'Manifoldcf';
          font-size: 15 * @rem;
          font-weight: 900;
          left: 0;
          position: absolute;
          text-align: center;
          text-transform: uppercase;
          top: 10px;
          width: 100%;
          z-index: 10;
        }

        &__cat {
          color: @white;
          display: block;
          font-family: 'Megafresh';
          font-size: 47 * @rem;
          font-weight: 400;
          position: absolute;
          text-align: center;
          top: 30px;
          width: 100%;
          z-index: 20;

          @media @smartphone {
            font-size: 30 * @rem;
          }
        }

        &__background {
          margin-top: 50px;
          position: relative;
          text-align: center;
          transition: all 200ms linear;
          z-index: 2;

          @media @tablet {
            box-shadow: none;
          }

          @media @smartphone {
            margin-top: 90px;
          }

          img {
            display: block;
            height: auto;
            margin: 0 auto;
            max-width: 90%;
            position: relative;
            width: 250px;
            z-index: 1;
          }
        }

        &__baseline {
          display: block;
          font-size: 14 * @rem;
          line-height: 1.5;
          padding: 20px 60px;
          text-overflow: ellipsis;
          white-space: nowrap;

          &--with-wrap {
            white-space: normal;
          }
        }

        &__content {
          left: 0;
          top: 0;
          width: 100%;
          z-index: 1;

          @media @smartphone {
            display: none;
          }
        }

        &__cta {
          padding-top: 18px;
          text-align: center;

          @media @tablet {
            height: 100%;
            left: 0;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 100;
          }

          .button {
            border: 2px solid @lightgrey;
            color: @lightgrey;
            display: inline-block;
            font-size: 14 * @rem;
            line-height: 1.3;
            max-width: 90%;
            min-width: 160px;
            padding: 5px 10px;
            text-decoration: none;
            transition: all 300ms linear;

            &:hover {
              background: @lightgrey;
              color: @white;
            }
          }
        }

        &__title {
          color: @lightgrey;
          display: block;
          font-size: 18 * @rem;
          font-weight: 700;
          line-height: 1.2;
          margin-top: 16px;
          overflow: hidden;
          padding: 0 20px 14px;

          @media @tablet {
            font-size: 16px;
            margin: 0;
            padding: 10px;
          }
        }
      }
    }
  }

  &--on-the-road {
    @media @smartphone {
      &:after,
      &:before {
        color: @lightgrey;
        font-size: 16 * @rem;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 10;
      }

      &:after {
        content: '→';
        right: 20px;
      }

      &:before {
        content: '←';
        left: 20px;
      }
    }

    .slick-dots {
      bottom: 100px;

      li {
        button {
          &:before {
            background: @yellow;
            border-radius: 99px;
            transition: width 150ms linear;
          }
        }

        &.slick-active {
          width: 20px;

          button {
            width: 20px;

            &:before {
              width: 20px;
            }
          }
        }
      }
    }

    .slider {
      &__item {
        position: relative;

        @media @tablet {
          width: 300px;
        }

        &__background {
          position: absolute;
          right: 20px;
          top: 0;
          z-index: 2;

          @media @smartphone {
            display: none;
          }

          img {
            max-height: 130px;

            @media @tablet {
              max-height: 100px;
            }
          }
        }

        &__content {
          background: #f8f8f8;
          box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
          margin: 40px 20px;
          min-height: 350px;
          padding: 50px 50px 80px;
          z-index: 1;

          @media @tablet {
            margin: 40px 10px;
            min-height: 300px;
            padding: 50px 20px 20px;
          }

          @media @smartphone {
            min-height: 200px;
            padding: 30px 40px 20px;
          }
        }

        &__desc,
        &__title {
          color: @lightgrey;
          font-family: 'Lato';
          font-size: 24 * @rem;
          font-weight: 700;
          line-height: 1.3;
          padding-bottom: 30px;

          @media @tablet {
            font-size: 20 * @rem;
            padding-bottom: 20px;
          }
        }

        &__desc {
          font-size: 16 * @rem;
          font-weight: 400;
          line-height: 1.3;

          @media @tablet {
            font-size: 14 * @rem;
          }
        }
      }
    }
  }
}
