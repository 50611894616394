/**
 * Embedded-video component styles
 */
.embedded-video {
  &__close {
    background: @black;
    border: 2px solid @white;
    border-radius: 99px;
    color: @white;
    display: block;
    font-size: 16 * @rem;
    font-weight: 700;
    height: 30 * @rem;
    line-height: 26 * @rem;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10px;
    transition: all 300ms linear;
    width: 30 * @rem;
    z-index: 2;

    &:after{
      content: '×';
    }

    &:hover,
    &:focus {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  &__open {
    background: none;
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;

    &:focus {
      border: 5px solid @yellow;
    }

    &:after {
      border: 2px solid @white;
      content: '';
      height: 80px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 100ms linear;
      width: 80px;

      :hover&,
      :focus& {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    &:before {
      border-bottom: 20px solid transparent;
      border-left: 33px solid @white;
      border-top: 20px solid transparent;
      content: '';
      height: 0;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 100ms linear;
      width: 0;
    }
  }

  &__poster {
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: block;
    height: 380px;
    position: absolute;
    right: 20px;
    top: 210px;
    transition: all 300ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
    width: 380px;

    @media @tablet {
      margin: 0 auto;
      position: relative;
      top: 0;
    }

    @media @smartphone {
      height: 300px;
      right: 0;
      width: 300px;
    }
  }

  &__source {
    background: #000;
    bottom: 0;
    height: calc(100% - 60px);
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 60px;
    transform: scale(0, 0);
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, visibility 0s ease-in-out 0.25s;
    visibility: hidden;
    width: 100%;
    z-index: 10;

    video {
      display: block;
      font-family: 'object-fit: contain';
      height: 100%;
      object-fit: contain;
      width: 100%;
    }

    .is-playing & {
      opacity: 1;
      transform: scale(1, 1);
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, visibility 0s ease-in-out 0s;
      visibility: visible;
    }
  }
  &__content{
    height: 100%;
  }
}
