

/**
 * Nav-map component styles
 */
.nav-map {
  font-size: 0;
  margin: 0 auto;
  max-width: 1024px;
  padding-top: 40px;
  text-align: center;

  &__area {
    display: inline-block;
    height: 459px;
    margin-right: 77px;
    position: relative;
    vertical-align: top;
    width: 452px;

    @media @tablet {
      margin-bottom: -80px;
      margin-right: 0;
      transform: scale(0.8);
      transform-origin: 50% 0;
    }

    @media @smartphone {
      display: block;
      left: 50%;
      margin: 0 -226px -180px;
      position: relative;
      transform: scale(0.6);
    }
  }

  &__img {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1;

    &--invisible {
      opacity: 0;
      z-index: 20;
    }

    &--partial {
      opacity: 0;
      transition: all linear 150ms;
      z-index: 10;

      &.visible,
      &.selected {
        opacity: 1;
      }

      &.hauts-de-france {
        left: 217px;
        top: 0;
      }

      &.normandie {
        left: 105px;
        top: 54px;
      }

      &.bretagne {
        left: 0;
        top: 110px;
      }

      &.grand-est {
        left: 287px;
        top: 46px;
      }

      &.ile-de-france {
        left: 219px;
        top: 97px;
      }

      &.pays-de-la-loire {
        left: 74px;
        top: 131px;
      }

      &.centre-val-de-loire {
        left: 168px;
        top: 112px;
      }

      &.bourgogne-franche-comte {
        left: 269px;
        top: 141px;
      }

      &.nouvelle-aquitaine {
        left: 93px;
        top: 205px;
      }

      &.auvergne-rhone-alpes {
        left: 239px;
        top: 225px;
      }

      &.occitanie {
        left: 146px;
        top: 318px;
      }

      &.paca {
        left: 322px;
        top: 310px;
      }
    }
  }

  &__nav {
    display: inline-block;
    margin-left: 77px;
    padding-top: 20px;
    vertical-align: top;
    width: 248px;

    @media @tablet {
      margin-left: 20px;
    }

    @media @smartphone {
      display: none;
    }

    .list {
      display: block;

      &__item {
        display: block;

        a {
          color: @darktext;
          display: block;
          font-size: 14 * @rem;
          font-weight: 700;
          line-height: 27px;
          padding: 0 20px;
          text-align: left;
          text-decoration: none;
          transition: all 150ms linear;

          &:hover,
          &.hover,
          &.selected {
            background: @yellow;
            color: @white;
          }
        }
      }
    }
  }

  &__mobile-nav {
    display: none;

    @media @smartphone {
      display: inline-block;
      font-size: 12 * @rem;
      height: 50px;
      margin-top: 30px;
      position: relative;
      width: 232px;

      select {
        height: 50px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 232px;
        z-index: 10;
      }

      .select-label {
        border: 2px solid @yellow;
        color: @darktext;
        font-size: 14 * @rem;
        height: 50px;
        left: 0;
        line-height: 46 * @rem;
        position: absolute;
        text-align: center;
        top: 0;
        width: 232px;

        &:after {
          border-right: 2px solid @lightgrey;
          border-top: 2px solid @lightgrey;
          content: '';
          height: 12px;
          position: absolute;
          right: 20px;
          top: 14px;
          transform: rotate(135deg);
          width: 12px;
        }
      }
    }
  }
}
