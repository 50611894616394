
/**
 * Background-video component styles
 */
.background-video {
  background: url('/home/assets/images/book-your-hotel/background-desktop.jpg') 50% 50% no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;

  @media @smartphone {
    background-image: url('/home/assets/images/book-your-hotel/background-mobile.jpg');
  }

  &__poster,
  &__source {
    font-family: 'object-fit: cover';
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__source {
    opacity: 0;
    transition: opacity 1s linear;

    .is-ready & {
      opacity: 1;
    }
  }

  &__play-pause {
    background: @white;
    border-radius: 100px;
    bottom: 1.25rem;
    display: block;
    height: 2.5rem;
    left: 1.25rem;
    position: absolute;
    width: 2.5rem;
    z-index: 10;

    &:after {
      border-bottom: 0.8rem solid transparent;
      border-left: 1rem solid @black;
      border-top: 0.8rem solid transparent;
      content: '';
      left: 54%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 150ms linear;
    }

    &:before {
      border-bottom: 0;
      border-left: 0.3rem solid @black;
      border-right: 0.3rem solid @black;
      border-top: 0;
      content: '';
      height: 1.4rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 150ms linear;
      width: 1rem;
    }

    &.paused {
      &:after {
        opacity: 1;
      }

      &:before {
        height: 0;
        width: 0;
      }
    }
  }
}
