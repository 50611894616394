
/**
 * Home page styles
 */
@import '../components/background-video/styles';

@import '../components/embedded-video/styles';

@import '../components/footer/styles';

@import '../components/gallery/styles';

@import '../components/nav-map/styles';

@import '../components/revealable/styles';

@import '../components/slider/styles';

@import '../components/timed-title/styles';

@import '../components/booking-engine/styles';

@import '../components/fixed-logo/styles';

@import '../frenchtrip/components/banner/styles';

@import '../partials/_variables';

@import './components/dfp/dfp';

@ondeanimduration: 1.5s;

@ondeanimeasing: cubic-bezier(0.230, 1.000, 0.320, 1.000);

.section {
  &__popin-content {
    background: @white;
    color: @greybrown;
    display: none;
    font-size: 14 * @rem;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20000;

    .icon-fonts-close {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 20;
    }

    &--wrapper {
      height: 100%;
      overflow: auto;
      padding: 50px 20px 20px;
      position: absolute;
      width: 100%;
    }
  }

  &--book-your-hotel {
    margin-bottom: 100px;
    position: relative;
    z-index: 600;

    @media @tablet {
      margin-bottom: 50px;
    }

    @media @mobile {
      margin-bottom: 50px;
    }

    .erm__notScrollPage & {
      visibility: hidden;
    }

    @media @smartphone {
      min-height: 400px;
    }

    .brand-logo {
      display: block;
      height: 71px;
      left: 50%;
      margin-left: calc(-174px / 2);
      position: absolute;
      top: -90px;
      width: 174px;
    }

    .section {
      &__background {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
        z-index: 1;

        picture {
          display: block;
          height: 100%;
          width: 100%;
        }

        &--with-yellow-filter {
          &:after {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 154, 0, 0.3) 100%);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }

      &__content {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 100%;
        z-index: 2;
      }

      &__header {
        text-align: center;
      }

      &__main {
        display: block;
        font-size: 0;
        margin: 0 auto;
        text-align: center;
        transition: all 150ms linear;

        &:focus {
          border: 4px solid @black;
        }

        &.hidden-on-load {
          opacity: 0;
          pointer-events: none;
        }

        .fake-input {
          background: @white;
          color: @darktext;
          display: inline-block;
          font-size: 14 * @rem;
          line-height: 50 * @rem;
          min-height: 50px;
          padding: 0 12px;
          text-align: left;
          text-decoration: none;
          transition: all 150ms linear;
          vertical-align: middle;
          width: 530px;

          .icon-fonts {
            color: @black;
            font-size: 24 * @rem;
            margin-right: 9px;
            transition: all 150ms linear;
          }

          @media @smartphone {
            display: inline-block;
            padding-right: 20px;
            width: auto;
          }
        }

        .fake-cta {
          background: @yellow;
          color: @black;
          display: inline-block;
          font-size: 14 * @rem;
          font-weight: 700;
          line-height: 50 * @rem;
          margin-left: 5px;
          min-height: 50px;
          padding: 0 20px;
          text-align: center;
          text-transform: uppercase;
          vertical-align: middle;

          @media @smartphone {
            display: none;
          }
        }
      }

      &__title {
        color: @white;
        font-family: 'Megafresh';
        font-size: 100 * @rem;
        font-weight: 400;
        line-height: 155 * @rem;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

        @media @tablet {
          line-height: 1;
        }

        @media @smartphone {
          font-size: 60 * @rem;
        }
      }
    }
  }

  &--everywhere {
    padding-bottom: 200px;
    position: relative;

    @media @tablet {
      padding-bottom: 50px;
    }

    .section__background {
      left: 50%;
      opacity: 0.6;
      position: absolute;
      text-align: right;
      transform: translate(-50%, 0);
      width: 1280px;

      @media @tablet {
        top: 30px;
        width: 100%;
      }

      @media @mobile {
        display: none;
      }
    }

    .section {
      &__title {
        color: @yellow;
      }

      &__popin-content {
        &--picto {
          padding: 0 0 20px;
          text-align: center;

          img {
            display: inline-block;
            max-width: 50%;
          }
        }

        &--title {
          font-size: 24 * @rem;
          font-weight: 700;
          line-height: 1.1;
          padding-bottom: 20px;
        }

        &--desc {
          font-size: 14 * @rem;
          line-height: 1.5;
          padding-bottom: 20px;
        }

        &--list-text {
          display: block;
          padding-left: 20px;
          position: relative;

          &:before {
            background: @yellow;
            border-radius: 99px;
            content: '';
            height: 10px;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            width: 10px;
          }
        }

        &--list {
          .list__item {
            margin-bottom: 20px;
          }

          .icon-fonts {
            background: @yellow;
            border-radius: 99px;
            color: @white;
            height: 28px;
            line-height: 28 * @rem;
            margin-right: 10px;
            overflow: hidden;
            text-align: center;
            width: 28px;
          }
        }
      }

      &__main {
        font-size: 0;
        margin: 0 auto;
        max-width: 960px;
        position: relative;
      }

      &__infos {
        color: @darktext;
        display: inline-block;
        font-size: 16 * @rem;
        font-weight: 700;
        line-height: 1.8;
        padding-left: 6%;
        position: relative;
        vertical-align: middle;
        width: 50%;

        @media @tablet {
          padding-left: 6%;
        }

        @media @smartphone {
          display: none;
        }
      }

      &__mobile-cta {
        display: none;

        @media @smartphone {
          bottom: 0;
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          text-align: center;
          width: 100%;
          z-index: 10;

          .button {
            border: 2px solid @white;
            bottom: 20px;
            color: @white;
            font-size: 15 * @rem;
            left: 50%;
            line-height: 46 * @rem;
            margin: 0 auto;
            min-width: 220px;
            padding: 0 10px;
            position: absolute;
            text-decoration: none;
            transform: translate(-50%, 0);
          }
        }
      }

      &__visual {
        display: inline-block;
        left: 110px;
        position: relative;
        vertical-align: middle;
        width: 40%;

        @media @tablet {
          left: 0;
          padding-left: 6%;
        }

        @media @smartphone {
          display: block;
          margin: 0 auto;
          max-width: 90%;
          padding-left: 0;
          width: 353px;
        }

        &--main {
          box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
          display: block;
          max-width: 100%;
          position: relative;
          z-index: 1;
        }

        &--picto {
          bottom: -40px;
          display: block;
          left: -157px;
          position: absolute;
          z-index: 2;

          @media @tablet {
            left: -77px;
          }

          @media @smartphone {
            display: none;
          }
        }
      }
    }
  }

  &.banner {
    padding-top: 40px;
  }

  &--best-offers {
    padding-top: 40px;

    @media @smartphone {
      padding-top: 50px;
    }

    .section {
      &__background {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      &__content {
        position: relative;
      }

      &__header {
        display: block;
        margin: 0 auto;
        max-width: 1280px;
        position: relative;
      }

      &__nav {
        font-size: 0;
        margin-top: 0;
        position: absolute;
        right: 20px;
        top: 0;

        @media @smartphone {
          display: none;
        }
      }

      &__title {
        color: @turquoise;
        font-weight: 300;
        line-height: 1;
        text-align: center;
      }
    }
  }

  &--inspirations {
    padding-top: 155px;

    @media @smartphone {
      padding-top: 55px;
    }

    .section {
      &__baseline {
        color: @darktext;
        font-size: 16 * @rem;
        line-height: 1.5;
        max-width: 80%;

        @media @smartphone {
          display: none;
        }
      }

      &__content {
        margin: 0 auto;
        max-width: 1024px;
        position: relative;
      }

      &__header {
        left: 0;
        padding-left: 20px;
        position: absolute;
        top: 0;
        width: 422px;
        z-index: 20;

        @media @tablet {
          width: 382px;
        }

        @media @smartphone {
          padding: 0 20px;
          position: relative;
          width: 100%;
        }
      }

      &__nav {
        font-size: 0;
        margin-top: 35px;

        @media @smartphone {
          display: none;
        }
      }

      &__title {
        color: @greybrown;
        font-family: 'Manifoldcf';
        font-weight: 300;
        margin: 20px 0;
        max-width: 80%;
        padding: 0;
        text-align: left;
        word-break: break-word;

        @media @smartphone {
          max-width: 100%;
          text-align: center;
        }
      }
    }
  }

  &--itineraries {
    padding-top: 60px;

    @media @smartphone {
      padding-top: 0;
    }

    .section {
      &__baseline {
        @media @smartphone {
          display: none;
        }

        &__text {
          color: @darktext;
          font-size: 16 * @rem;
          line-height: 1.5;
        }
      }

      &__content {
        margin: 0 auto;
        max-width: 1400px;
        position: relative;
      }

      &__cta {
        font-size: 0;
        text-align: center;

        @media @smartphone {
          display: none;
        }

        .button {
          border-color: @pink;
          color: @pink;
          display: inline-block;
          font-size: 14 * @rem;
          line-height: 44 * @rem;
          margin-top: 35px;
          min-height: 47px;
          min-width: 165px;
          padding: 0 20px;
          text-align: center;
          text-decoration: none;
          transition: all 150ms linear;

          &:hover {
            background: @pink;
            color: @white;
          }
        }
      }

      &__header {
        margin: 0 auto;
        max-width: 1280px;
        position: relative;
        text-align: center;
      }

      &__nav {
        bottom: 0;
        font-size: 0;
        margin-top: 35px;
        position: absolute;
        right: 20px;

        @media @smartphone {
          display: none;
        }
      }

      &__title {
        color: @pink;
        font-weight: 300;

        @media @smartphone {
          margin: 0;
          max-width: 100%;
        }
      }
    }

    a.slider__item:focus {
      outline: 3px solid @pink;
    }
  }

  &--our-services {
    padding: 50px 0 96px;

    @media @tablet {
      padding-bottom: 66px;
    }

    .section {
      &__content {
        margin: 0 auto;
        max-width: 1024px;
        text-align: center;
      }

      &__header {
        display: block;
      }

      &__title {
        color: @greybrown;
      }

      &__main {
        .list {
          font-size: 0;

          @media @smartphone {
            margin-bottom: -70px;
            padding-top: 70px;
          }

          &__animation {
            display: inline-block;
            min-height: 230px;
            position: relative;
          }

          &__item {
            display: inline-block;
            font-size: 20 * @rem;
            min-width: 256px;
            position: relative;
            width: 25%;

            @media @tablet {
              margin-bottom: 30px;
              width: 50%;
            }

            @media @smartphone {
              margin-top: -100px;
              min-width: 100px;
              transform: scale(0.65);
              vertical-align: top;
            }

            &--welcome {
              .list__big-title {
                left: 0;
                top: 0;
                width: 100%;
              }

              .needle-long {
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-name: animneedle;
                animation-timing-function: linear;
                left: 18px;
                position: absolute;
                top: 126px;
                transform-origin: 100% 100%;
                width: 31px;
              }

              .needle-short {
                animation-duration: 12s;
                animation-iteration-count: infinite;
                animation-name: animneedle;
                animation-timing-function: linear;
                left: 49px;
                position: absolute;
                top: 137px;
                transform-origin: 0 0;
                width: 16px;
              }

              .watch {
                display: block;
                height: auto;
                margin-top: 32px;
                max-width: 300px;
                width: 98px;
              }

              @keyframes animneedle {
                0% {
                  transform: rotate(0deg);
                }

                100% {
                  transform: rotate(360deg);
                }
              }
            }

            &--wifi {
              .list__big-title {
                left: 50%;
                padding-right: 22px;
                text-align: right;
                top: 160px;
                transform: translate(-50%, 0);
                width: 200px;

                @media @smartphone {
                  margin-left: -12px;
                  text-align: left;
                }
              }

              .mobile {
                display: block;
                height: auto;
                margin-top: 65px;
                max-width: 300px;
                width: 80px;
              }

              .wave-1 {
                animation-duration: @ondeanimduration;
                animation-iteration-count: infinite;
                animation-name: animonde1;
                animation-timing-function: @ondeanimeasing;
                height: auto;
                left: 3px;
                position: absolute;
                top: 48px;
                width: 23px;
              }

              .wave-2 {
                animation-duration: @ondeanimduration;
                animation-iteration-count: infinite;
                animation-name: animonde2;
                animation-timing-function: @ondeanimeasing;
                height: auto;
                left: -11px;
                position: absolute;
                top: 34px;
                width: 37px;
              }

              .wave-3 {
                animation-duration: @ondeanimduration;
                animation-iteration-count: infinite;
                animation-name: animonde3;
                animation-timing-function: @ondeanimeasing;
                height: auto;
                left: -24px;
                position: absolute;
                top: 19px;
                width: 51px;
              }

              @keyframes animonde1 {
                0% {
                  opacity: 0;
                  transform: scale(0.9) translate(10%, 10%);
                }

                33% {
                  opacity: 1;
                  transform: scale(1);
                }
              }

              @keyframes animonde2 {
                0% {
                  opacity: 0;
                  transform: scale(0.9) translate(10%, 10%);
                }

                13% {
                  opacity: 0;
                  transform: scale(0.9) translate(10%, 10%);
                }

                43% {
                  opacity: 1;
                  transform: scale(1);
                }
              }

              @keyframes animonde3 {
                0% {
                  opacity: 0;
                  transform: scale(0.9) translate(10%, 10%);
                }

                23% {
                  opacity: 0;
                  transform: scale(0.9) translate(10%, 10%);
                }

                53% {
                  opacity: 1;
                  transform: scale(1);
                }
              }
            }

            &--parking {
              .list__big-title {
                left: 0;
                top: 0;
                width: 100%;
              }

              .van {
                animation-duration: 0.3s;
                animation-iteration-count: infinite;
                animation-name: animvan;
                animation-timing-function: linear;
                display: block;
                height: auto;
                margin-top: 90px;
                max-width: 300px;
                transform-origin: 50% 100%;
                width: 180px;
              }

              .wheel-1,
              .wheel-2 {
                height: auto;
                position: absolute;
                transform-origin: 50% 50%;
                width: 31px;
              }

              .wheel-1 {
                animation-duration: 0.5s;
                animation-iteration-count: infinite;
                animation-name: animwheel;
                animation-timing-function: linear;
                left: 19px;
                top: 151px;
              }

              .wheel-2 {
                animation-delay: 0.75s;
                animation-duration: 0.5s;
                animation-iteration-count: infinite;
                animation-name: animwheel;
                animation-timing-function: linear;
                left: 124px;
                top: 151px;
              }

              .surf-1 {
                animation-duration: 0.2s;
                animation-iteration-count: infinite;
                animation-name: animsurf;
                animation-timing-function: linear;
                height: auto;
                left: 20px;
                position: absolute;
                top: 77px;
                width: 125px;
              }

              .surf-2 {
                animation-delay: 0.5s;
                animation-duration: 0.25s;
                animation-iteration-count: infinite;
                animation-name: animsurf;
                animation-timing-function: linear;
                height: auto;
                left: 42px;
                position: absolute;
                top: 70px;
                width: 87px;
              }

              @keyframes animvan {
                0% {
                  transform: rotate(1deg);
                }

                15% {
                  transform: rotate(-1deg);
                }

                30% {
                  transform: rotate(0deg);
                }

                45% {
                  transform: rotate(-1deg);
                }

                65% {
                  transform: rotate(0.5deg);
                }
              }

              @keyframes animwheel {
                0% {
                  transform: rotate(0deg) translate(0, -5%);
                }

                50% {
                  transform: rotate(180deg) translate(0, 0%);
                }

                100% {
                  transform: rotate(360deg) translate(0, 5%);
                }
              }

              @keyframes animsurf {
                0% {
                  transform: rotate(0deg) translate(0, -5%);
                }

                50% {
                  transform: rotate(1deg) translate(0, 0%);
                }

                100% {
                  transform: rotate(-1deg) translate(0, 5%);
                }
              }
            }

            &--breakfast {
              .list__big-title {
                left: 0;
                top: 156px;
                width: 100%;
              }

              .coffee-maker {
                display: block;
                height: auto;
                margin-top: 69px;
                max-width: 300px;
                width: 115px;
              }

              .steam-1,
              .steam-2,
              .steam-3 {
                position: absolute;
                transform-origin: 50% 100%;
              }

              .steam-1 {
                animation-delay: 0s;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-name: animmoke1;
                animation-timing-function: linear;
                height: 28px;
                left: 38px;
                top: 39px;
                width: auto;
              }

              .steam-2 {
                animation-delay: 0.2s;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-name: animmoke2;
                animation-timing-function: linear;
                height: 39px;
                left: 46px;
                top: 29px;
                width: auto;
              }

              .steam-3 {
                animation-delay: 0.4s;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-name: animmoke3;
                animation-timing-function: linear;
                height: 30px;
                left: 54px;
                top: 41px;
                width: auto;
              }

              @keyframes animmoke1 {
                0% {
                  height: 28px;
                  opacity: 0;
                  width: 6.5px;
                }

                60% {
                  height: calc(28px * 2);
                  opacity: 1;
                  transform: translate(0, -60%);
                  width: 6.5px;
                }

                99% {
                  height: calc(28px * 3);
                  opacity: 0;
                  transform: translate(0, -100%);
                  width: 6.5px;
                }

                100% {
                  height: 28px;
                  opacity: 0;
                  width: 6.5px;
                }
              }

              @keyframes animmoke2 {
                0% {
                  height: 39px;
                  opacity: 0;
                  width: 9.6px;
                }

                60% {
                  height: calc(28px * 2);
                  opacity: 1;
                  transform: translate(0, -60%);
                  width: 6.5px;
                }

                99% {
                  height: calc(28px * 3.5);
                  opacity: 0;
                  transform: translate(0, -100%);
                  width: 9.6px;
                }

                100% {
                  height: 28px;
                  opacity: 0;
                  width: 9.6px;
                }
              }

              @keyframes animmoke3 {
                0% {
                  height: 30px;
                  opacity: 0;
                  width: 9.4px;
                }

                60% {
                  height: calc(28px * 2);
                  opacity: 1;
                  transform: translate(0, -60%);
                  width: 9.4px;
                }

                99% {
                  height: calc(28px * 3);
                  opacity: 0;
                  transform: translate(0, -100%);
                  width: 9.4px;
                }

                100% {
                  height: 28px;
                  opacity: 0;
                  width: 9.4px;
                }
              }
            }
          }

          &__big-title {
            color: @greybrown;
            display: block;
            font-family: 'Megafresh';
            font-size: 50 * @rem;
            font-weight: 400;
            position: absolute;
            text-align: center;
            white-space: nowrap;
            z-index: 10;
          }

          &__infos {
            color: @darktext;
            font-size: 14 * @rem;
            line-height: 1.8;
            text-align: center;

            @media @smartphone {
              font-size: 18 * @rem;
              line-height: 1.5;
            }

            strong {
              font-size: 16 * @rem;

              @media @smartphone {
                font-size: 20 * @rem;
              }
            }
          }
        }
      }
    }
  }

  &--road-trip-spirit {
    position: relative;
    z-index: 598;

    @media @tablet {
      background: @yellow;
    }

    .section {
      &__background {
        left: 50%;
        position: absolute;
        text-align: right;
        transform: translate(-50%, 0);
        width: 1280px;

        @media @tablet {
          top: 30px;
          width: 100%;
        }

        @media @mobile {
          display: none;
        }
      }

      &__content {
        margin: 0 auto;
        max-width: 1024px;
        padding: 90px 20px 125px;
        position: relative;
        z-index: 2;

        @media @tablet {
          padding: 30px 0 80px;
        }

        @media @mobile {
          height: 330px;
          margin-bottom: 60px;
          padding-bottom: 0;
        }
      }

      &__header {
        text-align: center;
        width: 60%;

        @media @tablet {
          width: 100%;
        }
      }

      &__main {
        .editorial {
          background: @yellow;
          box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
          color: @darktext;
          font-size: 14 * @rem;
          line-height: 1.5;
          padding: 40px 200px 40px 67px;
          width: 72%;

          p {
            margin-top: 0;
          }

          @media @tablet {
            display: none;
          }
        }
      }

      &__title {
        color: @greybrown;
        font-family: 'Megafresh';
        font-size: 90 * @rem;
        font-weight: 400;
        padding-bottom: 30px;

        @media @mobile {
          font-size: 48 * @rem;
        }
      }
    }
  }

  &--where-do-you-go {
    margin: 0 auto;
    max-width: 1024px;
    padding-top: 50px;
    position: relative;

    &:after {
      bottom: 0;
      box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
      content: '';
      height: 40px;
      left: 50%;
      margin-left: -450px;
      position: absolute;
      width: 900px;
    }

    .section {
      &__content {
        background: @white;
        position: relative;
        z-index: 10;
      }

      &__footer {
        background: @white;
        clear: both;
        padding: 40px 0 20px;
        position: relative;

        .list {
          font-size: 0;
          text-align: center;

          &__item {
            color: @darktext;
            font-size: 14 * @rem;
            position: relative;
            text-align: center;
            width: 50%;

            @media @mobile {
              padding: 0 10px;

              &:first-child {
                &:after {
                  background: #e5e6e8;
                  content: '';
                  height: 100%;
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 2px;
                }
              }

              &:nth-child(2) {
                padding-left: 15px;
              }
            }

            .icon-fonts {
              color: @yellow;
              font-size: 44 * @rem;
              margin-right: 20px;

              @media @mobile {
                margin-right: 10px;
              }
            }
          }

          &__label {
            display: inline-block;
            text-align: left;
            vertical-align: middle;
            width: calc(100% - 74px);

            @media @mobile {
              width: calc(100% - 64px);
            }
          }
        }
      }
    }
  }
}

.embedded-video__poster {
  top: 20px;
}
