.banner {
  p {
    margin-top: 0;
  }

  .section__content {
    margin: 0 auto;
    max-width: 1024px;
  }

  .section__main {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 1025px) {
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  &--right {
    .section__main {
      @media (min-width: 1025px) {
        flex-direction: row-reverse;
      }
    }
  }

  &--center {
    @media (min-width: 768px) {
      background-image: url('/frenchtrip/assets/images/banners/map.png');
      background-position: calc(50% - 320px) 30%;
      background-repeat: no-repeat;
      background-size: 500px;
    }
  }

  &__image-container {
    max-width: 400px;
    position: relative;
    width: 80%;
    z-index: 1;

    @media (max-width: 1024px) {
      margin: 0 auto;
    }

    @media (min-width: 1025px) {
      width: 35%;
    }

    &:before {
      background-color: fade(@black, 30);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__image {
    display: block;
  }

  &__legende {
    color: @white;
    left: 50%;
    line-height: 1;
    position: absolute;
    text-align: center;
    text-shadow: 0 0 5px @black;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }

  &__crossed {
    font-size: 28 * @rem;
    font-weight: 900;
    left: 50%;
    opacity: 0;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;

    &:after {
      background-color: @white;
      content: '';
      height: 10%;
      left: 0;
      position: absolute;
      top: 58%;
      transform: translateY(-50%);
      transition: width 0.3s ease-in-out;
      transition-delay: 0.8s;
      width: 0;
    }

    .banner--visible & {
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-name: peekaboo;

      &:after {
        width: 100%;
      }
    }
  }

  &__real {
    font-family: 'Megafresh';
    font-size: 44 * @rem;
    opacity: 0;
    transition-delay: 2s;
    transition-duration: 0.3s;
    transition-property: opacity, visibility;
    transition-timing-function: ease;
    visibility: hidden;

    .banner--visible & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__text {
    padding: 200px 20px 20px;
    position: relative;

    .banner--left & {
      color: @black;
    }

    .banner--right & {
      color: @white;
    }

    .banner--center & {
      margin: 0 auto;
      max-width: 500px;
      padding: 40px 20px;
      text-align: center;
    }

    @media (max-width: 1024px) {
      margin-top: -180px;
      text-align: center;
    }

    @media (min-width: 1025px) {
      background-image: url('/frenchtrip/assets/images/banners/map.png');
      background-repeat: no-repeat;
      flex: 1;

      .banner--left & {
        background-position: calc(100% + 155px) 50%;
        padding: 40px 200px 40px 50px;
      }

      .banner--right & {
        background-position: calc(0% - 155px) 50%;
        padding: 40px 50px 40px 200px;
      }

      .banner--center & {
        background-image: none;
      }
    }
  }

  &__title {
    font-family: 'Megafresh';
    font-size: 64 * @rem;
    font-weight: 500;
    margin: 0 0 24px;
  }

  &__description {
    font-size: 16 * @rem;
    margin-bottom: 32px;

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
    }
  }

  &__cta {
    border: 2px solid;
    display: inline-block;
    font-size: 12 * @rem;
    font-weight: 900;
    margin-bottom: 16px;
    padding: 8px 50px;
    text-decoration: none;
    text-transform: uppercase;
    transition-duration: 0.25s;
    transition-property: color, background-color, border-color;
    transition-timing-function: ease;

    .festival-guide & {
      border-color: @black;

      &:hover {
        background-color: @black;
        border-color: @black;
        color: @white;
      }
    }

    .ride-style & {
      border-color: @white;

      &:hover {
        background-color: @white;
        border-color: @white;
        color: @ride-style-color;
      }
    }
  }

  &__badge {
    background-color: @white;
    display: none;
    max-width: 100px;
    padding: 15px;
    padding-top: 50px;
    position: absolute;
    top: 0;

    @media (min-width: 768px) {
      display: block;
    }

    .banner--left & {
      right: calc(50% - 320px);

      @media (min-width: 1025px) {
        right: 30px;
      }
    }

    .banner--right & {
      @media (min-width: 1025px) {
        left: 30px;
      }
    }

    .banner--center & {
      @media (min-width: 768px) {
        align-items: center;
        display: flex;
        height: 100%;
        left: calc(100% + 20px);
        padding-top: 0;
        width: 100px;
      }

      @media (min-width: 1025px) {
        left: calc(100% + 40px);
      }
    }
  }
}

@keyframes peekaboo {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  33% {
    opacity: 1;
    visibility: visible;
  }

  66% {
    opacity: 1;
    transform: translate(-50%, -50%);
    visibility: visible;
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -160%) scale(0.6);
    visibility: visible;
  }
}
