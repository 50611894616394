/**
 * Fixed-logo component styles
 */

.fixed-logo {
  bottom: 25px;
  left: 50%;
  opacity: 0;
  position: fixed;
  text-align: center;
  transform: translate(-50%, 0);
  transition: all 150ms linear;
  z-index: 600;

  &.bigOnInit {
    opacity: 1;

    .brand-logo {
      width: 130px;
    }

    &.visible {
      .brand-logo {
        width: 100px;

        @media @smartphone {
          width: 70px;
        }
      }
    }
  }

  &.visible {
    opacity: 1;
  }

  @media @smartphone {
    bottom: 20px;
  }

  .brand-logo {
    display: block;
    height: auto;
    margin: 0 auto;
    transition: all 150ms linear;
    width: 100px;

    @media @smartphone {
      width: 70px;
    }
  }
}
